.Hero{
    width: 100%;
    /* height: fit-content; */
    height: 100vh;
    max-height: 800px;
    min-height: 670px;
    margin-top: 35px;
}

.patti{
    /* position: absolute;
    top: clamp(650px, 90vh, 720px);*/

    position: absolute;
    top: 0;
    color: white;
    width: 100%;
    font-size: 1rem;
    height: 35px;
    text-align: center;

    background: black;
}
.patti label {
    margin: 0;
}
.hero-image-container{
    position: absolute;
    z-index: -99;
    width: 100%;
    height: 90vh;
    max-height: 720px;
    min-height: 650px;

    overflow: hidden;

    white-space: nowrap;
}
.slide, .carousel-inner , .carousel-item {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
  }

.hero-img{
    width: 100%;
    height: 100%;
    

    object-fit: cover;
    transform: scale(1.1);
}

.carousel-indicators, .carousel-control-prev, .carousel-control-next{
    z-index: 100 !important; 
    position: absolute;
}

.hero_title, .hero-des{
    color: white;
}

.hero-full{
    z-index: -1;
    height: 90vh;
    max-height: 720px;
    width: 100%;

    min-height: 650px;

    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0.23) 45.68%, rgba(0, 0, 0, 0.66) 100%);
    position: absolute;
}

.hero-txt{
    width: fit-content;
    position: relative;

    top: 25vh;
    left: 10vw;
    
}

.hero-des{
    width: 60vw;
    position: relative;
    top: 30px;
}

.hero-buttons{
    position: relative;
    top: 60px;

    display: flex;
    flex-direction: row;
    gap: 50px;
}
.butt1{
    background: transparent; 
    background-size: contain;
    padding: 12px 25px;
    color: white;
    border: 2px solid #FFF;
    box-shadow: none !important;
    
}


@media (max-width: 480px) { /* Adjust the max-width as needed for your breakpoint */
    
    .mob-text{
        font-size: 1.2rem !important;
        margin-left: 5vw;
        width: 75vw;
    }
    .hero-buttons{
        width: 80%;
        left: 5vw;
        gap: 30px;
        top: 90px;
        color: #14213D !important;
    }
    .hero-buttons button {
        background: #FCA311; 
        color: #14213D !important;
        border: none;
        /* backdrop-filter: blur(10px); */
        /* background: rgb(20, 33, 61, 0.8);  */
        /* background-image: linear-gradient(45deg, rgba(130, 167, 245, 0.7) 0%, rgba(130, 167, 245, 0.4) 45%, rgba(250, 125, 231, 0.8) 100%); */

         
    }

    .hero-txt{
        width: fit-content;
        position: relative;
    
        top: 25vh;
        width: 100%;
        left: 0;
        
    }
    .hero_title{
        font-size: 1.75rem;
        margin-left: 5vw;
    }
    
    
    
}

