.Appointment{
    width: 100%;
    height: 100svh;
    padding-top: 50px;
    background-repeat: no-repeat;
    background-size: cover;
}

.a-form{
    width: 40%;
    height: fit-content;
    backdrop-filter: blur(2px);
    background: rgb(255, 255, 255, 0.4);
    padding: 3% 5%;
    border-radius: 10px;
    max-width: 550px;
}

.a-form .form-group {
    margin-bottom: 5px;
}

.a-form .row_in{
    display: flex;
    justify-content: space-between;
}
.a-form label {
    
}

/* //////// Contact page */

.Contact_page{
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    background-color: #EAEAEA
}
.c_top{
    width: 100%;
    height: 30vh;
    background: #14213D;
    color: white;
    position: relative;
}

.c_top label{
    font-size: 5rem;
    position: absolute;
    bottom: 0;
    margin: 0;
}

.row_cards{
    max-width: 900px;
}
.row_cards, .row_cards_2{
    display: flex;
    width: 85%;
    gap: 5%;
    /* padding: 5%; */
    margin-top: 50px;
}
.row_cards_2{
    gap: 0;
    width: 100%;
    /* padding: 0; */
}
.c_card{
    background-color: white;
    height: 300px;
    width: 50%;
    max-width: 400px;
    padding: 2%;
    position: relative;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.4);
}
.c_location{
    width: 50%;
}

.row_cards_2 .a-form{
    width: 90%;
}

.c_card table {
    width: 90%;
}
.c_s_logos{
    width: fit-content;
    text-align: center;
}
.c_s_logos img{
    width: 40px;
}
.c_logos_row{
    width: fit-content;
    display: flex;
    gap: 30px;
    margin-top: 10px;
    /* position: absolute; */
    bottom: 0;
    /* gap: 10%; */
}
.c_app{
    width: 50%;
}
/* //////////////////// */

@media (max-width: 480px) {
    .a-form{
        width: 80%;
        font-size: 0.9rem;
    }

    .row_cards, .row_cards_2{
        flex-direction: column;
        gap: 20px;
    }
    .row_cards_2{
        gap: 0px;
    }
    .c_card{
        width: 90%;
    }
    .c_location{
        width: 100%;
        height: 400px;
    }
    .c_app{
        width: 100%;
    }
    .c_top label{
        font-size: 3rem;
    }
}