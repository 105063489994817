.measurements{
    position: relative;

    top: 10vh;

    height: 100vh;
    width: 100%;
}
/* measurement home page */

.m-home-mask{
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.7);
    position: absolute;
}

.m-home-left, .m-home-right{
    width: 50%;
    height: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    color: white;
}

.m-home-body{
    width: 60%;
    height: fit-content;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.m-home-body button{
    width: 50%;
}
/* .m-home-di{
    width: 50%;
} */


/* custom measu */

.c-m{
    width: 88vw;
    margin-top: 70px;
    height: fit-content;
}



.c-m-body{
    display: flex;
}

.c-m-buttons{
    text-align: center;

    display: flex;
    gap: 50px;
    width: fit-content;

    margin-top: 50px;
}
.input-wrap{
    position: relative;
    width: fit-content;
}

.c-m-left{
    width: 50%;
    position: relative;
}
.c-m-right{
    width: 40%;
    height: 55vh;
    position: absolute;
    right: 0;
}
.c-m-video{
    width: 100%;
    margin-top: 10px;
}
.c-m-img{
    width: 100%;
}
.lang{
    width: fit-content;
    color: white;
    display: flex;
    gap: 10px;
    background-color: #14213D;
    padding: 5px 10px;
    border-radius: 5px;
}
.lang_ind{
    width: fit-content;
    padding: 2px 5px;
    border-radius: 5px;
}
.lang label{
    margin: 0;
}
.lang_s{
  background-color: #fca311;   
}


.c-m-v-des{
    padding: 2%;
    display: flex;
    position: relative;
    background: rgba(20, 33, 61, 0.12);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.c-m-v-info{
    width: 100%;
    text-align: center;
}

/* input boxes */

.c-m-input, .c-m-input0{
    position: relative;
    top: 10%;
}


.c-m-des1{
    width: 50%;
}
.c-m-input0{
    width: fit-content;
    height: fit-content;
}

.c-m-i-box, .c-m-input0{
    width: fit-content;
    height: fit-content;
}

.c-m-i-r, .c-m-i-r1{
    display: flex;
    gap: 20px;

    margin-bottom: 30px;
}

.c-m-i0, .c-m-i1, .c-m-i2, .c-m-i3, .c-m-i21{
    width: 150px;
    padding: 15px;

    align-items: center;
    border: none;
    border-bottom: 2px solid #14213D;
    background: rgba(20, 33, 61, 0.12);
}

.c-m-i0{
    width: 450px;
}

.c-m-i2{
    width: 135px;
}

.c-m-b-c{
    display: flex;
    gap: 20px;
    width: fit-content;
}


/* set up measurements  */

.s-m-body{
    width: 90vw;  

}

.s-m-main{
    display: flex;
}
.s-m-left{
    display: flex;
    flex-direction: column;
    gap: 30px;

    align-items: center;
    justify-content: center;

}
.s-m-left, .s-m-right{
    width: 60%; 
}
.s-m-right{
    width: 30% !important;
}

.s-m-box{
    width: fit-content;
}

.s-m-box1{
    padding: 5px;
}
.s-m-box1 , .s-m-box2{
    border-radius: 16px 16px 0px 0px;
    border: 1px solid rgba(20, 33, 61, 0.60);
    background-color: #14213D;
    color: white;
    width: 100%;
    text-align: center;
}

.s-m-box2{
    border-radius:  0px 0px 16px 16px;
    display: flex;
}

.s-m-b-1 , .s-m-b-0{
    width: 50px;
    padding: 5px;
    border: 1px solid rgba(20, 33, 61, 0.60);
}

.right_most{
    border-bottom-right-radius: 16px;
}
.left_most{
    border-bottom-left-radius: 16px;
}
.s-m-b-0{
    
    border: none;
}

.s-m-b-1{
    border-top: none;
    border-bottom: none;
}

.s-m-img1, .s-m-img {
    width: 100%;
    height: auto;
}

.s-m-top{
    display: flex;
    position: relative;
    align-items: center;
    margin-top: 50px;
}

.backButton{
    color: white;
    top: -4vh;
    position: absolute;
    left: 5vw;
    z-index: 1000;
    font: 1.25rem;
    background-color: #14213D;
    padding: 5px 10px;
    border-radius: 5px;
}

.m-hover-img{
    position: absolute;
    right: 50px;
    width: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Butt1{
    height: fit-content;
}
.meas-butt{
    position:relative !important;
    right: 5vw !important;
}
.meas-title{
   margin-left: 28%; 
}

.page_1_left{
    /* width: 500px; */
    position: relative;
    left: -10vw;
}
.page_1_img{
    width: 30vw;
    right: 0;
    top: 0;
    position: absolute;
}

.units_m{
    display: flex;
    background-color: #14213D;
    color: white;
    padding: 2% 3%;
    gap: 10px;
    border-radius: 10px;
}
.unit_comp{
    padding: 2%;
}
.unit_comp_h{
    background-color: #FF7B00;
    border-radius: 8px;
}

.file_m_p_1{
    display: flex;
    width: 80%;
}

.saved_measurments_wrapper{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.saved_measurments{
    width: 50vw;
    z-index: 111;
    background-color: white;
    height: 75vh;
    border-radius: 10px;
    padding: 2%;
    padding-bottom: 0;
}
.svd_msr{
    width: 100%;
    padding-bottom: 7px;
    border-bottom: 1px solid #d9d9d9;
}
.svd_msr_body{
    height: calc(100% - 60px);
    /* background-color: #14213D; */
    width: 100%;
    margin-top: 20px;
    overflow: scroll;
}
.svd_msr_card{
    background-color: #d9d9d9;
    padding: 2%;
    border-radius: 5px;
    font-size: 0.8rem;
    margin-bottom: 5px;
}

.svd_msr_row1{
    width: 100%;
    display: flex;
    margin-top: 5px;
    gap: 5%;
}

.m_page0{
    height: calc(100svh - 66px);
    width: 100%;
    /* margin-top: 150px; */
    /* position: absolute; */
}

.m_page0_row{
    display: flex;
    width: 100%;
    height: calc((100svh - 66px) / 2 );
    perspective: 1000;
}
.m_page0_row_card{
    width: 50%;
    height: 100%;
    text-align: center;
    position: relative;
    background-position: center;
    color: white;
    transition: all 0.5s ease-in-out;
}
.m_page0_row_card .opac{
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    opacity: 0.6;
    z-index: 1;
}
.m_page0_row_card:hover{
    background-size: 200% !important;
    overflow: hidden;
    border: 2px solid white;
}
.m_page0_des{
    width: 80%;
    z-index: 2;
}

/* ///// Saved measurements */
.svd_measurements, .clone_m{
    margin-top: 50px;
    width: 90%;
}

.svd_section{
    display: flex;
    gap: 5%;
}
.svd_leftsection{
 
 width: 45%;
}
.svd_rightsection{
    background-color: #d9d9d9;
    width: 50%;
    padding: 10px 5%;
    border-radius: 10px;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.5);
}
.svd_m_row_ele{
    width: 100%;
    border-radius: 5px;
    background-color: #d9d9d9;
    padding: 3%;
    margin-bottom: 10px;
}
.svd_m_row_ele_top{
    display: flex;
    gap: 10px;
}
.svd_round_fill, .svd_round{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eaeaea;
}
.svd_round_fill{
    background-color: white;
    border: 5px solid #14213D;
}

/* ///////////// clone measurements */
.clone_m_section{
    display: flex;
    gap: 10%;
}
.clone_m_left{
    width: 50%;
}
.clone_m_left li{
    list-style: disc;   
}
.clone_m_right{
    width: 40%;
}
.clone_m_right_row{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

/*              */

@media (max-width: 480px) {
    .svd_leftsection{
        width: 100%;
        margin-bottom: 100px;
    }
    .clone_m_section{
        flex-direction: column;
        gap: 20px;
        margin-bottom: 100px;
    }
    .clone_m_right{
        width: 100%;
    }
    .clone_m_left{
        width: 100%;
    }
    .clone_m_right_row{
        width: fit-content;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .m_page0_wrapper{
        height: 100svh;
    }
}
@media (max-width: 480px){

    .preset-mob{
        width: 100vw;
        position: relative;
        font-size: 25px;            
    }
    .s-m-main{
        flex-direction: column;
        margin-bottom: 100px;
    }
    .s-m-left{
        width: 100%;
        transform: scale(0.8);
    }
    .page_1_left{
        left: 0;
    }
    .s-m-right{
        width: 100%;
    }
    .meas-title{
        margin-left: 0;

    }
    .backButton{
        top: 20px;
    }
    
    .c-m-body{
        display: flex;
        flex-direction: column;
    }
    .c-m-left{
        width: 100%;
    }
    .c-m-right{
        width: 100%;
        position: relative;
    }
    /* .Butt2{
        color: white;
        border: 2px solid white;
    } */
    .c-m-i21{
        width: 155px;
    }
    .size-but{
        width: 150px !important;
    }
    .c-m-input0{
        align-items: center;
    }
    .c-m-input{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .main-head{
        position: relative;
        width: 100%;
        height: 90%;
    }
    .c-m-des1{
        width: 100%;
        font-size: 15px;
        text-align:justify;
        margin-top: 20px;
    }
    .measure-box{
        /* position: relative; */
       /* top: 30vh; */
       /* left: 10vh;  */
    }
    .s-m-img{
        width: 95vw;
        height: auto;
        margin-top: 2vh;
    }
    .s-m-img1{
        width: 70vw;
        height: auto;
        margin-top: 2vh;
        left: 50%;
        /* transform: translateX(-50%); */
        position: relative;
    }
    .c-m-i-r{
        
    }
    .measurements{
        position: absolute;
        margin-bottom: 10vh;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .s-m-box{
        /* position: relative; */
        
        left: 0vw;
    }
    .s-m-box1{
        position: relative;
        margin-top: 2vh;
        width: 90vw;
        

    }
    
    .s-m-box2-scroll {
        border-radius: 0px 0px 16px 16px;
        border: 1px solid rgba(20, 33, 61, 0.60);
        padding-left: 40%; /* Adjust this value based on your needs */
        padding-right:40%; 
    overflow-x: auto;
    scroll-snap-type: x mandatory;
        width: 90vw;
        text-align: center;
        
        display: flex;
        
        white-space: nowrap;
        padding-top: 10px;
        padding-bottom: 10px;
         /* Ensures centering of items */
    -webkit-overflow-scrolling: touch;
    }
    .s-m-box2-scroll div {
        flex: 0 0 15%; /*Adjust width as needed*/
        scroll-snap-align: center;
        text-align: center; /* Center the text */
        transition: transform 0.3s ease-in-out;
        margin: 0 10px; /* Adjust for spacing between items */
    }
    
    .s-m-box2 {
        display: flex;
        padding: 10px;
        width: 90vw;
        background-color: white;
    }
    .c-m-i-r{
        margin-top: 20%;
        margin-left: 55%;
    }
    .c-m-i-r1{
        margin-top: 20%;
        margin-left: 55%;
    }
    .c-m-b-c{
        display: flex;
        gap: 20px;
        width: fit-content;
        align-items: center;
    }
    .s-m-b-0, .s-m-b-1 {
        
        border: none;
        /* flex: 0 0 auto; Prevents shrinking */
        /* padding: 10px; */
        /* margin-right: 10px; */
        z-index: -1;
        border-radius: 5px;
        cursor: pointer;

    }
    .s-m-box2-scroll > div:not(:last-child) {
        position: relative;
        margin-right: 15px; /* Adjust spacing between items as needed */
    }
    
    .s-m-box2-scroll > div:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 0;
        right: -14px;
        width: 1px;
        height: 100%;
        background-color: grey;
         /* Adjust color of the line */
    }
    
    
    .s-m-b-0:hover, .s-m-b-1:hover {
        background-color: #6D96FE;
        color: white;
    }
    .gen-mob{
        position: relative;
        
        top: 2vh;
        font-size: 30px !important;
    }
    .c-m-i0{
        position: relative;
        width: 320px !important;
        

    }
    .hei-mob{
        /* margin-left: 25%;
        margin-top: -0%; */
        height: fit-content;
    }
    .gender-mob{
        margin-top: 15%;
        margin-left: -26%;
    }
    .weight-mob{
        margin-top: 15%;
        margin-left: -26%;
        
    }
    .age-mob{
        margin-top: 0;
        
        
    }
    .c-m-buttons{
        margin-top: 30%;
    }
    .front-view-mob{
        position: absolute;
        margin-top: 2vh;
        left: 10%;

    }
    
    .side-view-mob{
        position: relative;
        margin-top: 3vh;
        top: 10vh;
        left: 10%;
        
        

    }
    .proceed-but{
        position: relative;
        margin-top: 20vh;
    }
    .page_1_img{
        position: absolute;
        width: 90%;
        top: 600px;
    }
    .proceed-but2{
        position: relative;
        margin-top: 30px;
        /* margin-bottom: 30vh; */
    }
    .head1{
        position: absolute;
        width: 90vw;
        /* margin-top: -10%; */
        
    }
    
    .c-m-des{
        position: relative;
        margin-top: 20%;
        text-align: justify;
        width: 85vw;
    }
    .c-m-video{
        width: 100%;
        margin-top: 0;
    }
    .c-m-img{
        position: relative;
        width: 100%;
        height: 100%;
        /* width: 80vw; */
    }
    .c-m-v-des{
        position: relative;
        width: 100%;
    }
    .body-head{
        position: relative;
        margin-top: 55%;
        
        left: 67%;
        
    }
    .input-wrap{
        margin-bottom: 10px;
    }
    .lang_mobile{
        margin-top: 10px;
    }
    .c-m-i-box{
        align-items: center;
        
    }
    .mob-input{
        position: relative;
        /* top: 25vh; */
        /* left: 25%; */
        margin-top: 0%;
    }
    .mob-input-l1{
        margin-top: -115%;
    }
    .mob-input-d1{
        margin-top: -75%;
    }
    .mob-right-d2{
        margin-top: -75%;
    }
    .mob-right-l2{
        margin-top: -115%;
    }
    .mob-right-l3{
        position: relative;
        
        margin-top: -75%;
        margin-left: -155%;
    }
    .mob-right-d3{
        position: relative;
        
        margin-top: -120%;
        margin-left: -80%;
    }
    .c-m-i1{
        width: 155px;
        
        
    }
    
    .chest-mob{
        margin-left: -20px;
    }
    .c-m-i2{
        width: 100px;
    }
    .mob-right1{
        left: 25%;
    }
    .mob-right2{
        left: 25%;
    }
    .mob-right-1{
        left: 25%;
    }
    .mob-right-2{
        left: 25%;
    }
    .m-home-left{
        width: 100%;
    }
    .m-home-right{
        width: 0;
    }
    .m-home-mask{
        display: flex;
        flex-direction: column;
    }
    .m-home-body{
        margin-top: 0vh;
        height: fit-content;
        flex-direction: column;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .input2{
        position: absolute;
        left: 50vw;
        top: 60vh;

    }
    .input1{
        position: absolute !important;
        top: 20vh;
    }
    .saved_measurments{
        left: 5vw;
        transform: translate(0, -50%);
        width: 90vw;
        z-index: 10000;
        height: 50vh;
        min-height: 450px;
    }

}
@media (max-width: 400px){
    .measure-butt{
        position: absolute;
        right: 0;
        top: 0;
    }
    .s-m-box{
        position: relative;
        
        left: 0vw;
    }
    .m_page0{
        margin-top: 0;
        height: 100svh;
    }
    .m_page0_row{
        height: 50%;
    }

}
@media (min-width: 400px) and (max-width: 480px){
    .measure-butt{
        position: absolute;
        right: 0;
        top: 0;
    }
    .s-m-box{
        position: relative;
        
        left: 2vw;
    }
    
    .m_page0{
        margin-top: 0;
        height: 100svh;
    }
    .m_page0_row{
        height: 50%;
    }

    
}

@media (min-width: 960px){
    
    .m_page0{
        height: fit-content;
        width: fit-content;
        /* margin-top: 86px; */
        /* position: absolute; */
        display: flex;
        /* flex-direction: row-reverse; */
    }
    .m_page0_wrapper{
        width: fit-content;
        height: fit-content;
        /* margin-left: 25vw; */
    }
    .m_page0_row{
        display: flex;
        width: fit-content;
        height: 200px;
        perspective: 1000;
        gap: 20px;
        position: relative;
    }
    .m_page0_row_card{
        width: 25vw;
        height: 100%;
        text-align: center;
        position: relative;
        background-position: center;
        color: white;
        transition: all 0.5s ease-in-out;
    }
    .m_page0_row_card .opac{
        width: 100%;
        height: 100%;
        left: 0;
        position: absolute;
        opacity: 0.6;
        z-index: 1;
    }
    .m_page0_row_card img{
        transition: transform .5s ease;
    }
    
    .m_page0_row_card:hover{
        /* background-size: 200% !important;
        overflow: hidden;
        border: 2px solid white; */
        transform: scale(1.1);
    }
    
    }
    

