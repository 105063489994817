
/* .profile_body{
    display: flex;
    gap : 3vw;    
} */

.profile-b-left{
    width: 30vw;
    position: fixed;
    
    /* background-color: aqua; */
    padding: 0 5%;
}

.profile-b-right{
    width: 60vw;
    margin-left: 30vw;
    padding-top: 5vh;
}

.Circle{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: rgb(255, 174, 0);
    margin-bottom: 20px;
}

.profile-card{
    margin-top: 5vh;
}
.profile-list{
    margin-top: 5vh; 
    display: flex;
    flex-direction: column;
    gap: 5px;    
}


/* Order card */


.o-m-close{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;

}
.order_card{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #cbcaca;
    margin-bottom: 30px;
    position: relative;
    
}

.o-c-t{
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #efefef;
    padding: 2%;
    gap: 10%;
    position: relative;
    font-size: 0.9rem;
}
.o-c-t-c1{
    position: relative;
    margin-right: 20%;
    font-size: 15px;
}
.o-c-d{
    display: flex;
    padding: 1%;
    height: 200px;
    
}
.order-img{
    width: 20%;
    /* height: 200px; */
    margin-right: 5%;
    object-fit: contain;
    position: relative;
}
.o-c-d-des{
    width: 50%;
    position: relative;
    
    margin-right: 5%;
}
.o-c-d-b{
    margin-top: 8vh;
    width: 150px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.padd_butt{
    padding: 5px 0px !important;
    width: 100% !important;
}
/* .padd_butt .Butt1{
    width: 100% !important;
} */
.order-card-butt{
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.6);
    width: 100%;
    border-radius: 5px;
}

.o-c-d-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}
/*   */

/* Order Modal */
.oder_opac{
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: black;
    opacity: 0.6;
}
.order_modal{
    position: fixed;
    width: 70%;
    height: 85vh;
    display: flex;
    background-color: white;
    z-index: 1000;
    padding: 2%;   
}
.order_modal_img{
    width: 50%;
    height: 100%;
    position: relative;
}

/* .order_modal_img .v-display-img-full{
    position: relative !important;
} */
.modal_order_img{
    width:80%;
    height:100%;
    object-fit:cover;
}
.order_modal_wrapper{
    width: 50%;
    height: 100%;
    overflow-y: scroll;
}
.order_modal_des{
    height: fit-content;
    width: 100%;
}
.order_Address{
    display: flex;
    width: 100%;
    position: relative;
}
.o-b-add, .o-d-add{
    width: 40%;
}
.o-b-add{
    position: absolute;
    right: 0;
}

.order_card_support{
    position: absolute;
    width: 25px;
    right: 5px;
    bottom: 5px;
    z-index: 1;
    /* background-color: #44bf00; */
    /* top: 0; */
}

.order_help_wrapper{
    width: 100vw;
    height: 100svh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 110;
    top: 0;
    left: 0;
    position: fixed;
}

.order_help{
    position: fixed !important;
    width: 50vw;
    height: 70vh;
    background-color: white;
    z-index: 111;
    border-radius: 5%;
    padding: 2%;
}

.order_h_header{
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
}
.order_help_elements{
    width: 95%;
    padding: 2%;
    border-bottom: 1px solid #d3d3d3;
}

.order_help_elements img{
    position: absolute;
    right: 2%;
    width: 15px;
    opacity: 0.7;
}
.order_help_elements:hover{
    background-color: #d3d3d358;
}

.order_help_left{
    position: absolute;
    /* left: 0; */
    /* margin-top: 10px; */
    width: 30px;
    opacity: 0.8;
}
@media (max-width: 480px){
    /* profile.css */

.profile_body {
    display: flex;
}

.profile-b-left {
    width: 250px;
    transition: transform 0.3s ease-in-out;
}

.profile-b-left.hidden {
    transform: translateX(-2500px);
}
.burger-menu21{
    position: fixed;
    top: 10vh;
    left: 0vh;
    color: black;
}


.burger-menu-icon3 {
    cursor: pointer;
    font-size: 24px;
    margin: 16px;
}

.close-sidebar {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    cursor: pointer;
}

.sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.sidebar-overlay.show {
    display: block;
}

@media (max-width: 480px) {
    .profile_body {
        flex-direction: column;
    }

    

    .profile-b-left {
        position: absolute;
        top: 0;
        left: 0%;
        width: 260px;
        height: 100%;
        background-color: white;
        z-index: 1000;
        transform: translateX(-2500px);
    }

    .profile-b-left.show {
        transform: translateX(0);
    }
    .order_card{
        margin-left: 5%;
        width: 90%;
        height: 60vh;
        border-radius: 10px;
        border: 1px solid #cbcaca;
        margin-bottom: 30px;
    }
    .profile-b-right{
        width: 100%;
        margin-left: 0;
    }
    .o-c-d-img{

        
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .verti_center{
        margin-right: -20%;
    }
    .o-c-d{
        margin-top: 3vh;
    }
    
    .less-mob{
        font-size: 15px;
    }
    
    .o-c-d-des{
        width: 100%;
        position: relative;
        width: 100%;
        margin-right: -20%;
    }
    .order-img{
        width: 100%;
    }
    .more-mob{
        width: 70px;
        height: 30px;
        color: white;
        background-color: black;
        border: none;
    }
    .track-mob{
        position: absolute;
        margin-left: -185%;
        top: 7vh;
        height: 20%;
        width: 35vw;
    }
    .view-mob{
        position: absolute;
        margin-left: -40%;
        top: 7vh;
        width: 35vw;
        height: 20%;
    }
    .o-c-t{
        
        height: 12vh;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #d3d3d3;
        padding: 2%;
        gap: 10%;
        position: relative;
        font-size: 0.9rem;
    }
    .o-c-t-c{
        margin-top: 10%;
        margin-left: 10px;
    }
    .o-c-d-b{
        position: relative;
        width: 80%;
        top: 28vh;
        margin-top: -2vh;
        
    }
    
    .order-id-mob{
        position: absolute;
        background-color: white;
        left: 0;
        width: 100%;
        height: 35%;
        top: 0%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .total-mob{
        position: absolute;
        left: 78%;
        top: 0px;
    }
    .o-c-t-c1{
        position: absolute;
        margin-right: 40%;
    }

    .order_modal{
        flex-direction: column;
        overflow-y: scroll;
        width: 90vw;
    }
    .order_modal_img{
        width: 100%;
    }
    .order_modal_wrapper{
        width: 100%;
        height: fit-content;
        overflow-y:visible;
    }
    .modal_order_img{
        width: 100%;
        object-fit: contain;
    }

    .order_help{
        width: 90vw;
        left: 5vw;
        transform: translate(0, -50%);
        border-radius: 3%;
    }
    .order_help_des{
        font-size: 0.8rem;
        width: 90%;
    }
}


       
}
/*    */

/* Order status */

.order_status{
    position: relative;
    width: 300px;
}
.o-s{
    position: relative;
    width: 90%;
    /* left: -50px; */
}
.o-s-line, .o-s-line1{
    position: absolute;
    height: 5px;
    width: 15%;
    background-color: #44bf00;
    /* left: 50px; */

}
.o-s-line1{
    background-color: #a3a3a3;
    width: 95%;
}

.o-s-circle{
    display: flex;
}
.o-s-single, .o-s-single-margin{
    position: relative;
    
}
.o-s-single-margin{
    margin-left: 20%;
}
.o-s-round-c, .o-s-round{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #a3a3a3;
    /* position: relative;
    left: 50%;
    transform: translateX(-50%); */
}
.o-s-round-c{
    border: none;
    width: 15px;
    height: 15px;
    background-color: #44bf00;
}



/*     */