.MobileNavBar {
    width: 100%;
  }
  
  .nav-mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
  
  .nav-mobile-logo {
    height: 40px;
  }
  
  .burger-button11 {
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    position: absolute;
    left: 10px;
  }
  
  .nav-mobile-menu {
    position: fixed;
    top: 8vh;
    right: 0%; /* Start off-screen */
    width: 50%;
    height: fit-content;
    background-color: #14213D;
    overflow-y: auto;
    transition: transform 0.3s ease;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
  .nav-mobile-menu.open {
    transform: translateX(70%); /* Slide in from the left */
  }
  
  .nav-mobile-menu div {
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #eaeaea75;
    font-size: 20px;
  }
  
  .nav-mobile-menu div:last-child {
    border-bottom: none;
  }
  
  @media (min-width: 480px) {
    .nav-mobile {
      display: none;
    }
  }
  
  @media (max-width: 480px) {
    .nav-desktop {
      display: none;
    }
    
    .nav-mobile {
      display: block;
    }
  }
  