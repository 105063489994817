.filter_svg .st0{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st1{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st2{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st3{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st4{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st5{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st6{
    stroke: #14213D !important;
    fill:#14213D !important;
}.filter_svg .st7{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st8{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st9{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st10{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st11{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st12{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st13{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st14{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st15{
    stroke: #14213D !important;
    fill:#14213D !important;
}.filter_svg .st16{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st17{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st18{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st19{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st20{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st21{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st22{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st23{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st24{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st25{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st26{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st27{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st28{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st29{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st30{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st31{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st32{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st33{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st34{
    stroke: #14213D !important;
    fill:#14213D !important;
}.filter_svg .st35{
    stroke: #14213D !important;
    fill:#14213D !important;
}.filter_svg .st36{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st37{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st38{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st39{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st40{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st41{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st42{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st43{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st44{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st45{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st46{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st47{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st48{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st49{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st50{
    /* stroke: #FFFFFF !important; */
    /* fill:#14213D !important; */
}.filter_svg .st51{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st52{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st53{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st54{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st55{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st56{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st57{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st58{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st59{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st60{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st61{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st62{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st63{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st64{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st65{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st66{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st67{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st68{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st69{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st70{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st71{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st72{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st73{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st74{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st75{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st76{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st77{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st78{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st79{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st80{
    /* stroke: #FFFFFF !important;
    fill:#14213D !important; */
}.filter_svg .st81{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st82{
    /* stroke: #FFFFFF !important; */
    /* fill:#14213D !important; */
}.filter_svg .st83{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st84{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st85{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st86{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st87{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st88{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st89{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st90{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st91{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st92{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st93{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st94{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st95{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st96{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st97{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st98{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st99{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st100{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st101{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st102{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st103{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st104{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st105{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st106{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st107{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st108{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st109{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st110{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st111{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st112{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st113{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st114{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st115{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st116{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st117{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st118{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st119{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st120{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st121{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st122{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st123{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st124{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st125{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st126{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st127{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st128{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st129{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st130{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st131{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st132{
    /* stroke: #FFFFFF !important;
    fill:#14213D !important; */
}.filter_svg .st133{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st134{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st135{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st136{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st137{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st138{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st139{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st140{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st141{
    stroke: #FFFFFF !important;
    /* fill:#14213D !important; */
}.filter_svg .st142{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st143{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st144{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st145{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st146{
    /* stroke: #FFFFFF !important;
    fill:#14213D !important; */
}.filter_svg .st147{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st148{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st149{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st150{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st151{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st152{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st153{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st154{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st155{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st156{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st157{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st158{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st159{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st160{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st161{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st162{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st163{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st164{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st165{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st166{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st167{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st168{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st169{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st170{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st171{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st172{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st173{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st174{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st175{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st176{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st177{
    stroke: #FFFFFF !important;
}.filter_svg .st178{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st179{
    stroke: #FFFFFF !important;
}.filter_svg .st180{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st181{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st182{
    /* stroke: #FFFFFF !important;
    fill:#14213D !important; */
}.filter_svg .st183{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st184{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st185{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st186{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st187{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st188{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st189{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st190{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st191{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st192{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st193{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st194{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st195{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st196{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st197{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st198{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st199{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st200{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st201{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st202{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st203{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st204{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st205{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st206{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st207{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st208{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st209{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st210{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st211{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st212{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st213{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st214{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st215{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st216{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st217{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st218{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st219{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st220{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st221{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st222{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st223{
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st301 {
    stroke: #FFFFFF !important;
    fill:#14213D !important;
}.filter_svg .st306 {
    stroke: #14213D !important;
}.filter_svg .st313 {
    stroke: white !important;
}


/* //////////////// Kurta //////////////////// */

.filter_svg .st313{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st314{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st315{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st316{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st317{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st318{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st319{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st320{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st321{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}
.filter_svg .st322{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st323{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st324{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st325{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st326{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st327{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st328{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st329{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st330{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st331{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st332{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st333{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st334{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st335{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st336{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st337{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st338{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st339{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st340{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st341{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st342{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st343{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st344{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st345{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st346{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st347{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st348{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st349{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st350{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st351{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st352{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st353{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st354{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st355{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st356{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st357{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st358{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st359{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st360{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st361{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st362{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st363{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st364{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st365{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st366{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st367{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st368{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st369{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}
.filter_svg .st370{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st371{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st372{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st373{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st374{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st375{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st376{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st377{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st378{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st379{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st380{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st381{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st382{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st383{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st384{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st385{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st386{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st387{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st388{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st389{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st390{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st391{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st392{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st393{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st394{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st395{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st396{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st397{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st398{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st399{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st400{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st401{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st402{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st403{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st404{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st405{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st406{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st407{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st408{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st409{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st410{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st411{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st412{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st413{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st414{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st415{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st416{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st417{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st418{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st419{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st420{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st421{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st422{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st423{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st424{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st425{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st426{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st427{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st428{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st429{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st430{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st431{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st432{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st433{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st434{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st435{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st436{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st437{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st438{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st439{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st440{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st441{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st442{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st443{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st444{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st445{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st446{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st447{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st448{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st449{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st450{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st451{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st452{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st453{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st454{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st455{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st456{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st457{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st458{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st459{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st460{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st461{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}

/* /////////////// cls  */

.filter_svg .cls-0{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-1{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}
/* .filter_svg .cls-2{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
} */
.filter_svg .cls-3{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-4{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-5{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-6{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-7{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-8{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-9{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-10{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-11{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-12{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-13{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-14{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-15{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-16{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-17{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-18{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-19{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-20{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-21{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-22{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-23{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-24{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-25{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-26{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-27{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-28{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-29{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-30{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-31{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-32{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-33{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-34{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-35{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-36{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-37{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-38{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-39{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-40{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-41{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-42{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-43{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-44{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-45{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-46{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-47{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-48{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-49{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-50{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-51{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-52{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-53{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-54{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-55{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-56{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-57{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-58{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-59{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-60{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-61{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-62{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-63{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-64{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-65{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-66{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-67{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-68{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-69{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-70{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-71{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-72{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-73{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-74{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-75{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-76{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-77{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-78{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-79{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-80{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-81{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-82{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-83{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-84{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-85{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-86{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-87{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-88{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-89{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-90{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-91{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-92{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-93{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-94{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-95{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-96{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-97{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-98{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-99{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-100{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-101{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-102{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-103{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-104{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-105{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-106{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-107{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-108{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-109{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-110{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-111{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-112{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-113{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-114{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-115{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-116{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-117{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-118{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-119{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-120{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-121{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-122{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-123{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-124{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-125{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-126{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-127{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-128{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-129{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-130{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-131{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-132{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-133{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-134{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-135{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-136{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-137{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-138{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-139{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-140{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-141{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-142{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-143{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-144{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-145{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-146{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-147{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-148{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-149{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-150{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-151{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-152{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-153{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-154{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-155{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-156{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-157{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-158{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-159{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-160{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-161{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-162{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-163{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-164{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-165{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-166{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-167{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-168{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-169{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-170{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-171{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-172{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-173{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-174{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-175{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-176{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-177{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-178{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-179{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-180{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-181{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-182{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-183{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-184{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-185{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-186{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-187{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-188{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-189{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-190{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-191{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-192{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-193{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-194{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-195{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-196{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-197{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-198{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-199{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-200{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-201{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-202{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-203{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-204{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-205{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-206{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-207{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-208{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-209{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-210{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-211{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-212{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-213{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-214{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-215{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-216{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-217{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-218{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .cls-219{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}
.filter_svg .st220{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st221{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st222{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st223{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st224{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st225{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st226{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st227{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st228{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st229{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st230{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st231{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}.filter_svg .st232{
    stroke: #FFFFFF !important;
    fill: #14213D !important;
}