.presets_main{
    width: 100%;
    margin-top: 60px;
}
.Presets-hero{
    width: 100%;
    height: 300px;

    background-image: url("../assets/mbg1.jpg");
    background-size: cover;
    background-position: center;
}
.p-sec{
    display: flex;
    background-color: #f9f8fa;
    padding-top: 100px;
}

.row_presets{
    width: 100vw;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 5vw;
}
.pre-left{
    width: 25vw;
}
.pre-right{
    width: 80vw;
    /* left: 30vw;
    position: relative; */
}
.col-sm-3 {
    padding: 0 !important;
}
.row{
    margin: 0;
}

.pre-card{
    border: 1px solid #a4a4a4;
}

.P-card3{
    height: 100%;
    /* width: 22vw; */
    background-size: cover;
    background-position: center;
    color: white;
    position: relative;
    
    max-width: 300px;
    /* backdrop-filter: brightness(70%); */

    
}

.P-card3:hover{
    background-size: 150%;
}

.pre-card-body{
    /* background-color: #14213d; */
    color: white;
    backdrop-filter: blur(5px);
    background: rgb(20, 33, 61, 0.5) !important;
    display: flex;
    flex-direction: column;

    text-align: left !important;

    color: white !important;

    padding: 2% 5%;
    height: 70px;
    max-width: 300px;
    align-items: normal !important;
    border-radius: 0 !important;
}
.pre-card-body span{
    width: fit-content;
}

.pre-filter{
    width: fit-content;
}

.pre-f-ele{
    width: fit-content;
}


.Hero1{
    width: 100%;
    /* height: fit-content; */
    height: 70vh;
    max-height: 800px;

}

.hero-image-container1{
    position: absolute;
    z-index: -99;
    width: 100%;
    height: 70vh;
    max-height: 720px;
    
    z-index: -2;
    overflow: hidden;

    white-space: nowrap;
}

.pre-hero-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -99;
}
.hero-full1{
    z-index: 0;
    height: 70vh;
    max-height: 720px;
    width: 100%;

    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0.63) 45.68%, rgba(0, 0, 0, 0.66) 100%);
    position: absolute;
}

.hero-txt1{
    width: fit-content;
    position: relative;
    text-align: center;
    top: 25vh;
    
}

.hero-des1{
    
    position: relative;
    top: 10px;
    color: white;
}

.pre-pagenum-sec{
    width: 100%;
}

/* Preset Item */

.pre-item{
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
}
.pre-sec-wrap{
    margin-top: 150px;
    width: 100%;
    height: fit-content;
    display: flex;    
}

.pre-item-left{
    width: 40vw;
}
.pre-item-right{
    width: 45vw;
    margin-left: 2vw;
}

.pre-item-des {
    margin-bottom: 20px;
    margin-top:10px;
}
.pre-item-des-sec{
    width: 95%;
    position: relative;
    left: 5vw;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-cart{
    width: 30% !important;
    background-color: #14213D;
}
.pre-item-img{
    width: 100%;
    height: 100%;
    /* zoom: 1.5; */
    object-fit: contain;
}
.c_p_main{
    height: 85vh;
    max-height: 800px;
    width: 80%;
    margin-left: 100px;
    /* min-height: 700px; */
}
.c_p_main img{
    width: 100%;
    height: 85vh;
    object-fit: contain;
    object-position: top;
}
.carousel-img-main .carousel-indicators{
    display: none;
}

.carousel-img-main1{
    height: 80vh !important;
    width: 50% ;
}
.carousel-img-main1 .v-d-body {
    width: 100%;
}
.presets_img_status{
    width: fit-content;
    overflow: hidden;
    position: absolute;
    /* left: 5vw; */
    height: 100%;
}
.img_list_display{
    width: 80px;
    height: 100px;
    overflow: hidden;
}
.img_list_display img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* transform: scale(2); */
}

.img_list_display img:hover{
    /* transform: scale(1); */
}
.list_d_v{
    background-color: #d3d3d3;
}
.list_d_v img{
    transform: scale(0.7) !important;
}
.quantity{
    display: flex;
    width: 25%;

    border: 1px solid black;
    position: relative;
    border-radius: 5px;
    padding: 1%;
}
.quantity_buts{
    width: 30px;
    height: fit-content;
}
.quantity_buts svg{
    width: 30px;
    height: 30px;
}
.qty_des{
    text-align: center;
    width: calc(100% - 30px);
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1%;
    top: 0;
}

.qty_butts{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 0.8;
}

.share_preset{
    width: 50px;
}

.more-info {
    display: none;
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
    cursor: pointer;
    border-radius: 7px;
    margin-right: 25px;
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    border: 2px solid #FFF;
    box-shadow: 4px 4px 0px 0px rgba(255, 255, 255, 0.64);
}

.carousel-img-main , .carousel-img-main1 {
    width: 45%;
    position: sticky;
    top: 0px;
    display: flex;
    height: fit-content;
    margin-left: 5vw;
}

.product-info {
    margin-top: 20px;
    margin-bottom: 5px;
}

.dot,.item-pricing-sm {
    display: none;
}

.discount_is{
    text-decoration: line-through;
    opacity: 0.8;
    font-size: 1.2rem;
}

.p_f_d_t{
    display: flex;
}
.p_h_i{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 5px;
}
.p_h_i:hover{
    color: white;
    background-color: #14213D;
    border-radius: 5px;
}
.h_p_h{
    background-color: #14213D;
    color: white;
    border-bottom: #14213D 2px solid;
}
.p_f_d_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
}
.p_fab_n{
    padding: 0 5%;
    background-color: #14213D;
    color: white;
    height: 24.5px;
}
.fab_p{
    background-color: #f9f9f9;
    padding: 3% 3%;
    /* padding-bottom: 50px; */
    border-radius: 20px;
    position: relative;
}
.p_fab_war{
    width: 40%;
    position: absolute;
    bottom: 2px;
}

.presets_container{
    width: 90%;
}
.presets_container .row{
    gap: 0;
}

.preset_img{
    /* border: 1px solid #EAEAEA; */
    overflow: hidden;
    position: relative;
    

    border-radius: 10px;
}
.preset_img img{
    width: 100%;
    aspect-ratio: 72/91;
    object-fit: cover;

    border-radius: 10px;
    transition: transform .5s ease;
}
.preset_main_img:hover{
    /* animation: zoom_in 0.4s linear;
    animation-duration: 2s;    
    animation-iteration-count: 1; */
    transform: scale(1.3);
    
}

@keyframes zoom_in {
    0% {
        transform: scale(1);
    }
  
    100% {
        transform: scale(1.3);
    }
  }

.presets_bottom{
    background-color: white;
    color: black;
    padding: 5%;
    height: 150px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 2px 13px 16px 0px rgba(0, 0, 0, 0.2);
}

.presets_loading{
    text-align: center;
    height: 40vh;
}
.presets_loading img {
    width: 100px;
}

.p_main_title{
    font-size: 2rem;
}

.preset_item_acc .accordion-item{
    width: 100% !important;
}

.preset_item_acc .accordion-button:not(.collapsed){
    background-color: transparent !important;
}

.p_m_des_main{
    width: 60%;
}

.cards_presets{
    padding-right: 0;
}

.cutomize_icon{
    position: absolute;
    right: 7px;
    height: 35% !important;
    top: -3px;
    object-fit: contain !important;
    width: fit-content !important;
    aspect-ratio: unset !important;
    /* box-shadow: 4px 4px 0px 0px rgba(48, 48, 48, 0.2); */
}

@media (max-width: 835px) {

    .presets_img_status{
        display: none;
    }
    .item-title {
        font-size: xx-large;
    }

    .presets_bottom{
        height: 150px;
        font-size: 0.8rem !important;
    }

    .item-counter {
        /* display: none; */
    }

    .dot {
        display: block;
        margin-right: 15px;
        margin-left: 15px;
    }

    .price-info-container {
        /* display: flex; */
        justify-content:start;
        align-items: center;
    }
    .item-pricing-sm {
        font-size: x-large;
        display: block;
        margin-top: 0;
    }

    .pre-sec-wrap {
        display: flex;
        margin-top: 68px;
        flex-direction: column;
    }
    .pre-item-left{
        width: 100%;
    }

    .pre-item-right{
        width: 100%;
        margin: 0;
        padding: 15px;
    }
    .pre-item-img {
        height: 75svh;
        object-fit: contain;
    }
    .img_list_display{
        width: 60px;
        height: 60px;
    }
    .c_p_main{
        height: 100%;
        width: 100%;
        margin-left: 0px;
    }
    .c_p_main img{
        height: 100%;
    }
    .pre-product-des, .item-pricing, .product-info {
        display: none;
    }
    .pre-item-des{
        font-size: 0.7rem;
        width: 95%;
        margin-bottom: 25px;
    }
    .more-info {
        display: block;
        width: 50%;
        height: 50px;
    }
    .carousel-img-main , .carousel-img-main1 {
        width: 100%;
        position: relative;
        left: 0;
        margin: 0;
    }
    .items-btns-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin-left: 25px; */
    }
    .share_preset{
        width: 40px;
    }
    .quantity{
        width: 40%;
    }
    .add-cart{
        width: 40% !important;
    }
}

@media (min-width: 836px) and (max-width: 1119px){
    .presets_bottom{
        height: 140px;
        font-size: 0.8rem !important;
    }
    .preset_name{
        font-size: 1rem !important;
    }
}
@media (min-width: 1220px) {
    .carousel-img-main , .carousel-img-main1 {
        width: 45vw;
    }
    .pre-item-right{
        margin-left: 0;
    }
    
}

/* atk starts */

.p-sec {
    justify-content: center;
  }
  
  .pre-left {
    width: 15vw;
  }
  
  .pre-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
  }
  
  .atk-mobile-filter {
    display: none;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    /* justify-content: center; */
    align-items: center;
  }
  
  .pre-card {
    border: 1px solid #a4a4a4;
    /* width: 13rem; */
    width: 15vw;
    height: 45vh;
    /* height: 17rem; */
  }
  .atk-pagination{
    width: fit-content !important;
  }
  .atk-pagination li {
    cursor: pointer;
  }
  
  .atk-nav {
    display: flex;
  }
  
  .pre-card-body {
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0;
    width: 100% !important;
  }
  
  .atk-active {
    background-color: #14213d;
    color: white;
  }
  
  .atk-active:hover {
    background-color: #14213d;
    color: white;
  }

  .presets_cat_box {
    position: relative;
    padding: 20px 10px;
    cursor: pointer;
    overflow: hidden;
  }

  .category_presets_header{
    border-bottom: 1px solid black;
    width: fit-content;
  }
  
  .explore_more_presets {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
  .explore_more_presets_wrapper{
    position: relative;
  }
  .cat_presets_arr{
    width: 25px;
    opacity: 0.7;
    left: -100%;
    transition: left 0.5s ease;
    position: absolute;
  }
  .explore_more_presets:hover .cat_presets_arr {
    left: 70%; 
  }
  .explore_more_presets_wrapper:hover{
    border-bottom: 1px solid #686868;
  }
  .p_main_title div{
    width: 70%;
    text-align: center;
  }
  
  @media only screen and (max-width: 860px) {
    .atk-mobile-filter {
      /* display: block; */
      display: flex;
      position: relative;
      z-index: 540;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .pre-card {
      width: 35vw;
      height: 35vh;
    }
    .p_main_title div{
        width: 100%;
        text-align: center;
      }
  
    /* .txt-font1 {
      font-size: 1rem !important;
    } */
    .p-sec {
      flex-direction: column;
      /* width:90vw; */
      gap: 3rem;
      justify-content: center;
      align-items: center;
    }
    .atk-desk-filter {
      display: none;
    }
  
    .atk-filter {
      color: black;
    }

    .preset_img img{
        height: 200px;
    }

    .p_m_des_main{
        width: 60%;
    }

    .p_main_title{
        width: 80%;
        font-size: 1.2rem;
    }

    /* .fab_p{
        flex-direction: column;
    }

    .fab_p_div_1{
        width: 100% !important;
        height: 150px;
    }
    .fab_p_div_2{
        width: fit-content !important;
    }

    .fab_p_div_3{
        position: relative;
        top: 0;
        text-align: center;
        transform: translate(0, 0);
    } */
     .fab_p{
        padding-bottom: 50px;
     }
     .card_des_f{
        position: absolute;
        bottom: 10px;
        width: 80%;
     }
  }
  /* atk ends */