.Checkout{
    margin-top: 120px;
    min-height: 500px;
    overflow-x: hidden;
}

.cart-page{
    display: flex;
    left: 8vw;
    height: 100%;
    position: relative;
    width: fit-content;
}

.Cart{
    /* height: fit-content; */
    width: 55vw;
}

.cost{
    position: fixed;
    width: 30vw;
    height: fit-content;
    top: 25vh;
    padding: 20px;
    right: 5vw;
    border: 1px solid rgb(20, 33, 61, 0.5);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cart_switch{
    position: absolute;
    right: 5vw;
    bottom: 0;
}

/* cart card */

.cart-card{
    display: flex;

    width: 50vw;
    height:170px;
    
    background-color: #d3d3d3;
    /* margin-bottom: 30px; */
    position: relative;
}
.cart-c-des{
    position: relative;
    left: 5%;
    top: 40%;
    display: flex;
    width: 65%;    
    height: fit-content;
}
.cart-des-right{
    position: absolute;
    right: 0;
}
.cart-img{
    width: 20%;
    position: relative;
    /* padding: 10px 10px; */
}
.c-im{
    width: 100%;
    height: 100%;
}
.card_brandImg{
    width: 70px;
}
.c-options{
    position: absolute;
    right: 0;
    transform: scale(0.7);
}
.c-options{
    width: 40px;
    height: 40px;
    background-color: #FCA311;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.2rem;
}
.c-options label{
    margin: 0;
}
.c-det{
    /* bottom: 0; */
    transform: scale(0.5);
}

.c-det-mes{
    position: absolute;
    right: 0;
    bottom: 0;
}
.c-det-mes img{
    width: 30px;
}
.cart-options{
    position: absolute;
    bottom: 0;
    left: 25%;

    display: flex;
    gap: 20px;

    color: #0c577d;
}

.c-o-e:hover, .c-o-e1:hover{
    text-decoration: underline;
}

.check_nav{
    /* width: 100vw; */
    display: flex;
    position: absolute;
    top: 85px;
    gap: 10px;
}
.check_nav_item{
    height: 30px;
}

.check_nav_item label{
    margin: 0;
}
.check_nav_item hr{
  border: none;
  border-top: 1px dotted black;
  /* color: #fff; */
  /* background-color: #fff; */
  height: 1px;
  width: 30px;
  opacity: 0.8;
}
.check_nav_line{
    height: 1px;
    width: 50px;
    display: flex;
    background-color: #14213D;
}

.empty_cart{
    position: relative;
    left: 13vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    top: 10vh;
}

.c-m-modal{
    border-top: #EAEAEA solid 1px ;
    width: 80%;
    position: relative;
    left: 20%;
    bottom: 0;
    top: 100%;
    height: fit-content;

    padding: 10px;
    background-color: #EAEAEA;

    margin-bottom: 30px;
}

.c-m-t-1{
    display: flex;
    gap: 50px;
}


/* Costs */
.cost-des{
    height: fit-content;
    position: relative;
    /* top: 10vh; */
}
.cost-row1{
    width: 100%;
    display: flex;
    padding: 10px 7%;
    background-color: #14213D;
    color: white;
}
.cost-row{
    width: 100%;
    display: flex;
    padding: 5px 3%;
}
.c-left{
    width: 75%;

}
.c-right{
    width: 25%;
    text-align: right;
}

.payment_confirm{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.payment_process{
    width: fit-content;
    height: fit-content;
    text-align: center;
    border: 1px #EAEAEA solid;
    padding: 30px;

}

/* /// */

.wrapper{
    height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#eee
}

.checkmark__circle{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}
.checkmark{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    animation-delay: 1s;
}
.checkmark__check{
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    animation-delay: 1s;
}
.fabric_cart_modal{
    position: fixed;
    width: 50vw;
    height: fit-content;
    max-height: 80vh;
    z-index: 1000;
    /* overflow-y: scroll; */
    overflow: hidden;
    background: white;
}
.f_c_m_W{
    width: 100%;
    height: fit-content;
    max-height: 80vh;
    z-index: 1000;
    /* overflow-y: scroll; */
    overflow: hidden;
}
.cart_f_modal{
    background-color: #14213D;
    color: white;
}
.cart_f_modal label{
    margin: 0;
}
.fabric_cards_wrapper{
    /* height: 90%; */
    /* height: 300px; */
    height: calc(0.7 * 80vh);
    overflow-y: scroll;
    padding: 10px;
}
.fabrics_wrapper{
    height: fit-content;
}
.fabrics_wrapper .preset_fabric_details{
    margin-bottom: 20px;
}
.opac_cart{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    background: rgb(48, 48,48, 0.5);
}

.fabrics_wrapper{
    height: fit-content;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
    /* columns: 2; */
}

.cart_img_pos img{
    object-position: left;
}

.secure{
    position: absolute;
    right: 0;
    top: -50px;
}

.secure img{
    width: 40px;
}
@keyframes stroke{100%
    {stroke-dashoffset: 0}
}
@keyframes scale{0%, 100%
    {transform: none}
    50%
    {transform: scale3d(1.1, 1.1, 1)}
}
@keyframes fill {100%
    {box-shadow: inset 0px 0px 0px 30px #7ac142}
}

@media only screen and (max-width: 480px) {

    .fabric_cart_modal{
        position: absolute;
        width: 95vw;
    }
    .mob_f_des{
        font-size: 0.7rem;
    }
}
@media only screen and (max-width: 768px) {
    .cost-checkout {
        position: static;
        width: 85vw;
    }
    .cart-page {
        display: flex;
        flex-direction: column-reverse;
    }
    .cart-items-card {
        width: 85vw;
    }
    .urcart {
        width: 91vw;
        margin-top: 2vh;
    }
    .accordion, .accordion-item, .accordion-header, .accordion-button, .accordion-body {
        width: 85vw;
    }
    .cart-img{
        width: 35%;
    }
    .cart-c-des{
        width: 50%;
        flex-direction: column;
    }
    .cart-options{
        left: 40%;
    }
    .cart-des-right{
        position: relative;

    }

    .secure{
        position: absolute;
        top: 0;
    }
    .secure label{
        display: none;

    }
}

