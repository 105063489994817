.measurement_hover_comp{
    display: flex;
    width: 22vw;
    position: absolute;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s linear;
    /* z-index: -1; */
    /* flex-direction: row-reverse; */
    /* z-index: -1; */
    /* left: -20vw; */
    /* right: -20vw; */
}



.m_data_comp{
    width: 90%;
    height: 100%;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;
    background-color: white;
}
.m_hook{
    position: relative;
}

.m_hook_wrapper{
    position: relative;
    height: fit-content;
}

.m_hook_arrow_right{
    width: 0; 
    height: 0; 
    
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    
    border-left: 20px solid white;
}
.m_hook_arrow_left {
    width: 0; 
    height: 0; 

    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent; 
    
    border-right:20px solid white; 
  }