.feel_fabric {
    width: 100%;
    height: 100svh;
    overflow: hidden;
    display: flex;
  }
  
  .fab_left {
    width: 40vw;
    height: 100%;
    padding-top: 45px;
    background: rgb(48, 48, 48, 0.2);
  }
  .feel_right1 {
    width: 50vw;
    left: 5vw;
    position: relative;
  }
  
  .feel_fabric_list {
    column-count: 3;
    height: fit-content;
  }
  .feel_fabric_wrapper {
    position: relative;
    height: 80%;
    margin-top: 20px;
    overflow-y: scroll;
    width: 90%;
  }
  
  .feel_fabric_list .card1 {
    /* margin-bottom: 40px; */
  }
  .f-r-top {
    margin-top: 10vh;
  }
  
  .fab_sel {
    width: 150px;
    display: flex;
    flex-direction: column;
  }
  .fab_sel img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .fab_sel span {
    font-size: 0.8rem;
  }
  .feel_fabric_list .s-f-price {
    background: transparent;
  }
  .fab_check {
    position: absolute;
    bottom: 50px;
  }
  
  .fab_check label {
    margin: 0;
    height: fit-content;
  }
  
  /* atk starts */
  
  .atk-fabholder {
    display: flex;
    flex-wrap: wrap;
  }
  
  .atk-btn {
    position: relative;
    top: 9%;
    background-color: #14213d;
    visibility: hidden;
  }
  
  @media only screen and (max-width: 500px) {
    .atk-lfab {
      /* display: none; */
      position: absolute;
      background: #d6d6d6;
      z-index: 1000;
      transform: translateX(-100%);
      transition: 1s;
      display: flex;
      flex-direction: column;
      align-items: end;
    }
    
    .atk-fabholder{
      width: 95vw !important;
      display: flex;
      gap: 10px;
      overflow: scroll;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .atk-close{
     padding: 1rem;
    }
  
    .atk-btn{
      visibility: visible;
      margin-top: 80px;
    }
  
    .atk-flab-slide {
      transform: translateX(0%);
    }
  
    .fab_left {
      background: #d6d6d6;
      width: 100svw;
    }
  
    .feel_right1 {
      width: 100svw;
      left: 0;
      padding: 2rem;
    }

    .feel_right1 .tut_arrow{
      top: 9%;
      margin-top: 60px;
      left: 35%;
    }
  }
  /* atk ends */