.Fabric{
    height: 100vh;
    width: 100%;
    /* overflow: hidden; */
}

.fabricbody, .StyleBody, .SelectionBody{
    display: flex;
    background-color: #E5E5E5;
}
.Navigation_section{
    width: 5vw;
    height: 100vh;
    background-color: aqua;
}
/* fabric modal */
.s-modal{
    position: absolute;
    height: 100vh;
    width: 70vw;
    /* max-width: 400px; */

    background: no-repeat;
    background-size: cover;
    
    /* transform: translateY(-50%); */
    left: 30vw;
    /* background-color: aqua; */

    z-index: 102;
    /* box-shadow: 0px 0px 60px 0px rgba(20, 33, 61, 0.48); */
}

.f-back-dark{
    background-color: #14213D;
    width: 100%;
    height: 100%;
    position: absolute;

    z-index: 101;
    opacity: 0.5;
}

.s-hoverDes{

    position: absolute;
    bottom: 0px;

    background-color: #14213D;
    color: white;

    width: 100%;
    padding: 2%;
}
.s-h-container{
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
}
.m-f-arrow{
    position: absolute;
    top: 0;
    width: 30px;
}
.m-f-img-l, .m-f-img-r{
    position: absolute;
    top: 40%;
    width: 50px;
}
.m-f-img-l{
    left: 0;
}
.m-f-img-r{
    right: 0 ;
}
.m-main-img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transform: scale(1.2); */
}

.s-h-des-1{
    width: 45%;
    /* position: relative; */
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.s-f-des-img{
    width: 40%;
}

.s-h-line{
    width: 1px;
    height: 90%;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
}
.s-h-icons{
    width: 50%;
    /* position: absolute; */
    /* right: 1%; */
    
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: fit-content;
}
.jodh_shirt{
    display: none !important;
}
.s-h-icons-r{
    display: flex;
    width: 90%;
}
.white_bg{
    color: black !important;
    background: white !important;
    box-shadow: none !important;
}
.s-h-f-icon{
    
    width: 20%;
    
    text-align: center;
    margin-right: 20px;
}
.s-h-f-i-img{
    width: 40px;
}

/* select fabric */
.selectFabric{
    z-index: 1000;
}
.selectFabric, .styleSection{
    width: 30vw;
    height: 100vh;

    /* background: rgba(20, 33, 61, 0.1);*/
    background-color: white;

    position: relative;
}

.styleSection{
    background : white;
}

.viewFabric{
    width: 65vw;
    height: 100vh;
    left: 5vw;
    position: relative;
}

.s-navigation{
    width: fit-content;
    position: absolute;
    /* top: 30px; */
    height: 100vh;
    
    display: flex;
    left: 31vw;
}
.s-nav_list{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: fit-content;
    position: relative;
    left: 50%;
    /* transform: translateX(-50%); */
    transform: translate(-50%, -70%);
    top: 50%;
    z-index: 101;
}

.s-nav{
    border-radius: 20px;
    padding: 2px 15px;
    transform: scale(0.9);
    border: 1px solid rgb(20, 33, 61);
}
.s-nav2{
    border-radius: 20px;
    padding: 2px 15px;
    transform: scale(0.9);
    border: 1px solid rgb(20, 33, 61);
}
.s-nav-shade{
    padding: 2px 15px;
    background: rgba(20, 33, 61, 0.8);
    border-radius: 20px;
    color: white
}
.s-nav-shade2{
    padding: 2px 15px;
    background: rgba(20, 33, 61, 0.8);
    border-radius: 20px;
    color: white
}
.nav-line{
    width: 1px;
    opacity: 0.8;
    height: 40px;
    background-color: #000000;
}
.s-logo {
    color: #000;
    position: absolute;
    right: 0;
    top: 30px;
    
}

.same_fabric{
    width: 100%;
    text-align: center;
    top: 60px;
    position: absolute !important;
    
}

.suit_single{
    position:absolute;
    display:flex;
    width:fit-content;
    height:40px;
}

/* Selection section */
.sectionSectionFull{
    width: 100%;
    height: 100%;

    background: rgb(20, 33, 61, 0.1) ;
}
.Selection-Body{
    width: fit-content;
}
.sel-card-sec{
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
.sel-card{
    position: relative;
    width: 11vw;
    height: 11vw;
    border-radius: 10px;
    background-color: #d9d9d9;
    
}
.sel-top{
    position: relative;
    width: 100%;
    padding: 5% 10%;
}

.sel-card img{
    position: absolute;
    right: 5px;

    transform: scale(0.9);
}
.sel-ins{
    width: 30vw;
    backdrop-filter: blur(10px);
    background: rgb(255, 255, 255, 0.5);

    /* background-color: white; */
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    z-index: 101;
    /* top: 100px; */
    position: relative;
    left: 100px;
    margin-top: 30px;
}
.viewOpac button {
    left: calc( 120px + 10vw ) ;
    top: 20px;
    position: relative;
    background: #FCA311;
    color: white !important;
}

.viewOpac button::after {
    background: #14213D;
}

.tut{
    width: 50%;
}
.sel-ins-des{
    
    height: fit-content;
    position: relative;
    font-size: 1rem;
    top: 50%;
    /* transform: translateY(-50%); */
}

.viewOpac button{
    z-index: 101;
}

/* selectFabric */

.selectbody{
    

    width: 85%;
    /* min-width: 420px; */

    position: absolute;

    top: 15vh;
    left: 50%;
    transform: translateX(-50%);
}

.s-title{
    position: relative;
    margin-bottom: 20px;
}
.filterButt{
    position: absolute;
    right: 0;

    opacity: 0.8;
    font-size: 1.2rem ;

    top: 50%;
    transform: translateY(-50%);
}

.fabricList{
    /* height: 80vh; */
    height: fit-content;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    column-count: 2;
    column-gap: 10px;
}
.mob-main-2{
    height: calc(85svh - 100px);
    overflow-y: auto;
}
.main-mon-2{
    position: relative;
    margin-bottom: 20px;
    break-inside: avoid-column;
}
.card1{
    height: 100px;
    background-size: cover;
    background-position: center;
    color: white;
    width: 100%;
    position: relative;
    /* margin-bottom: 10px; */
    break-inside: avoid;
    box-shadow: 0px 12px 20px -10px #000000

}
.s-f-i-c-bottom{
    /* border-top: 18px solid #FCA311;
	border-left: 17px solid transparent;
	border-right: 17px solid transparent; */
    background-color: #FCA311;
	height: 18px;
	width: 100%;
    font-size: 0.7rem;
    /* color: white; */
    margin-bottom: 10px;   
    position: relative;
    z-index: -10;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.s-f-i-c-bottom label {
    margin: 0 !important;
}
.fab_inf{
    position: absolute;
    display: flex;
    top: 0;
    /* width: 60%; */
    font-size: 0.8rem;
}

.fabric_list_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    position: absolute;
    }

.sel-info-div{
    /* position: absolute; */

    /* background-color: rgb(20, 33, 61, 0.5); */
    backdrop-filter: brightness(50%);
    width: 100%;
    height: 100%;
    z-index: 100;
}
.sel-info{
    width: 15px;
    height: 15px;
    /* object-fit: contain; */
    /* position: absolute; */
    z-index: 100;
    margin-right: 5px;
    
}
.s-f-price{
    
    background: rgba(20, 33, 61, .9);
    width: fit-content;

    position: absolute;
    right: 0;
    padding: 1%;
    border-radius: 10%;

}

.s-f-foot{
    width: 100%;
    height: fit-content;
    padding: 5px;
    text-align: center;

    font-size: 0.8rem;
    position: absolute;

    bottom: 0;

    background: rgba(20, 33, 61, .5);
    
}

.s-options-fabric {
    display: flex;
    position: relative;
    width: fit-content;
    height: 40px;
    top: 10px;
    width: 100%;
    /* background-color: #bdbbbb; */

}
.shirt-option{
    position:relative;
    left:-20px;
    display:flex;
    width:fit-content;
}
.s_fabric_type{
    /* width: fit-content; */
    display: flex;
    /* padding: 20px 20px; */
    width: 40%;
    position: relative;
    /* gap: 10px; */
    background-color:#d6d6d6;
}
.flex_grow {
    flex-grow: 1;
}
.s_f_t_c, .s_f_t_c1, .s_f_t_c2{
    text-align: center;
    border-radius: 15px;
    color: white;
    /* width: 33%; */
    /* flex-basis: 100%; */
    height: fit-content;
    position: relative;
    color: black;
    /* background-color: #14213D; */
}
.s_f_t_c2{
    width: 100%;
    background-color: rgb(20, 33, 61);
    height: 100%;
    border-radius: 0 ;
}
.s_f_t_c1{
    position: absolute !important;
}
.s-extra, .s-extra1, .s-extra2{
    height: 100%;
    width: 40px;
    background-color: #d6d6d6;
    position: relative;
    right: 10px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: -100;
}
.s-extra2{
    z-index: 0 !important;
}
.s-extra1{
    /* right: -10px; */
    left: 10px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.sel-card-img{
    width: 100%;
    height: 100%;
    transform: scale(2);
    object-fit: contain;
    top: -1vw;
    position: relative !important;
}
.img-plus{
    opacity: 0.5;
}
/*              */

/* View Section */

.viewOpac{
    position: absolute;
    width: 70vw;
    height: 100vh;
    background: rgb(20, 33, 61, 0.8);
    z-index: 100;
    left: 30vw;
    top: 0;
}
.viewBody{
    display: flex;
    
    bottom: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    
}

.v-d-body{
    /* width: 80%; */
    height: 100%;

    position: relative;
    /* left: 50%;
    transform: translateX(-50%); */
    /* z-index: 100 !important; */
    overflow: hidden;
}

.v-display-img-full{
    /* transform:scale(3);
    left: -30%;
    top: 0%; */
    
}
.dummy{
    z-index: 0 !important;
}
.image-scroll-sec{
    z-index: 100;
    position: absolute;
    bottom: 5%;
}
.img-scroll-dots{
    display: flex;
    width: fit-content;
}
.img-scroll-dots{
    
}
.round-left{
    /* display: none; */
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    width: 27px;
    backdrop-filter: blur(5px);
    background: rgb(255, 255, 255, 0.5);
    
    border: none;
    position: relative;
    right: -1px;
}
.round-right{
    /* display: none; */
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    width: 27px;
    backdrop-filter: blur(5px);
    background: rgb(255, 255, 255, 0.5);
    border: none;
    position: relative;
    right: 1px;
}
.i-s-dot, .i-s-dot1{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #14213D;
    opacity: 0.6;
}
.i-s-d-m{
    display: flex;
    width: fit-content;
    height: fit-content;
    gap: 20px;
    padding: 5%;
    position: relative;
    backdrop-filter: blur(5px);
    background: rgb(255, 255, 255, 0.5);
    border: none;
}
.i-s-dot1{
    transform: scale(1.5);
    opacity: 1;
}


.f-loading{
    width: 100%;
    height: 100%;
    /* background: transparent; */
    z-index: 100;
    position: relative;
    text-align: center;
}
.f-loading img{
    position: relative;
    transform: scale(0.75);
    top: 40%;
}

.v-display{
    position: relative;
    height: 100%;
}

.v-display-img, .v-display-img-full {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;   
}

.v-display-img{
    top: 20%;
    transform: scale(1.2);
}

.v-display-img-shirt{
    top: 50% !important;
    transform: scale(2);
    left: 0 !important;
}
.v-display-img-blazer{
    top: 25% !important;
    transform: scale(1.5);
    left: 0 !important;
}
.v-display-img-sadri{
    top: 38% !important;
    transform: scale(1.8);
    left: 0 !important;
}
.hands{
    z-index: 52 !important;
}

.def_pant, .def_shirt{
    z-index: 51 !important;
}
.def_sadri{
    z-index: 0 !important;
}
.v-display-default{
    display: none;
}
.v-display-img-pant{
    top: -30% !important;
    transform: scale(1.5);
    left: 0 !important;
}
.cart-v-display-img-shirt{
    transform: scale(1.5);
    top: 25%;
}
.cost_break_up td{
    padding: 10px;
}
.cost_break_up th{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
}
.cost_break_up{
    margin-bottom: 30px;
}

.cart-v-display-img-pant{
    transform: scale(1.5);
    top: -25%;
}
.cart-v-display-img-sadri{
    transform: scale(1.5);
    top: 25% !important;
}
.def_cloth-shirt{
    z-index: 60;
}
.cart-v-display-img-blazer{
    transform: scale(1.5);
    top: 25%;
}
.img-left{
    left: 20%;
}


.v-icons{
    display: flex;
    flex-direction: column;
    gap: 20%;

    position: absolute;
    right: 0;
    top: 10vh;

    height: fit-content;
    width: fit-content;

    align-items: center;
    
}
.v-share , .v-love{
    width: 30px;
    height: 30px;
}

.v-share img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.v-love{
    background: url('../assets/icon/love.png');
}

.arrow-d{
    position: absolute;
    width: 100%;
    top: 40%;
}
.v-arr-r, .v-arr-l{
    position: absolute;
    /* bottom: 0;*/

    z-index: 101;
    /* top: 50%; */
    /* transform: translateY(-50%); */

    width: 25px;
}
.v-arr-r{
    right: 13%;
}
.v-arr-l{
    left: 13%;
}
/* view right section */
.v-des{
    top: 15vh;
    width: 30%;
    position: relative;
    
}
.v-des_section{
    height: fit-content;
    width: fit-content;

    text-align: right;

    position: absolute;
    right: 10%;
    
    /* margin-top: 20%; */
    /* transform: translateY(-0%); */
}
.discount_p{
    margin-bottom: 0 !important;
}
.v-display{

    /* height: 75vh; */
    width: 70%;
    
    /* background: rgba(0, 0, 0, 0.5); */
}

.v-checkout{
    position: relative;
    top: 5px;
    left: 20%;
    /* background-color: #FCA311; */
}

/* view Detials modal */

.opac{
    z-index: 9999;
    width: 70vw;
    height: 100%;
    position: absolute;
    background-color: #000000;
    opacity: 0.5;
    left: -5vw;
}
.view_details{
    position: absolute;
    width: 70%;
    max-height: 80vh;
    overflow-y: scroll;
    height: fit-content;
    background-color: white;
    z-index: 10000;
    border-radius: 20px;
}
.v-modal-top{
    /* top: 0;
    position: fixed; */
    width: 90%;
    margin-top: 20px;
    display: flex;
    margin-bottom: 10px;
}
.v-m-t-t, .v-m-t-t1{
    text-align: center;
    /* width: 50%; */
    padding: 1%;   
}
.v-m-t-t{
    border-bottom: 3px rgb(20, 33, 61) solid;
}

.v-m-des{
    width: 90%;
    height: fit-content;
    
    position: relative;
}
.v-m-d-f{
    width: fit-content;
    text-align: center;
}
.v-m-d-f-comp{
    display: flex;
    /* gap: 10px; */
    margin-bottom: 30px;
    font-size: 1rem;
}
.v-m-d-f-img{
    width: 130px;
}
.single_s_des{
    /* width: 33%; */
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 0.9rem;
    margin-right: 10px;
}
.single_s_des img{
    width:90%;
}

.eachStyleDes{
    display: flex;
    gap: 50px;
    height: fit-content;
}

.s-des-left{
    width: 20%;
}
.s-des-right{
    width: 70%;
}
.styleDes-line{
    background-color: #000;
    width: 90%;
    height: 1px;
    opacity: 0.5;
}

.s-comp-wrap{
    /* width: fit-content; */
    margin-bottom: 10px;
}

/*              */

/* Filters */

/* Fabric Filter */

.f-filter{
    position: absolute;
    width: 20vw;
    height: 100vh;   
    overflow-y: scroll;
}
.f-filterBody{
    width: 100%;
    position: relative;
}
.f-fil-comp{
    width: 80%;
}
.f-balls{
    background-color: aqua;
    position: relative;
    width: 18%;
    height: 0;
    padding-bottom: 18%;
    border-radius: 50%;
    margin-right: 7%;
}
.f-balls:hover{
    /* width: 19%; */
    /* padding-bottom: 18%; */
    border: 1px #14213Da5 solid ;
}
.f-c-comp{
    width: 90%;
    position: relative;
}
.f-c-i{
    position: absolute;
    right: 0;
}
.f-fil-comp .row{
    gap: 0;
}


/* Styles Filter */

.styleSection{
    overflow-y: scroll;
}

.s-filter-wrapper{
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    top: 10vh;

    margin-bottom: 300px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.s-filter-wrapper, .s-filter-row, .s-filter-type{
    
    width: fit-content;
    height: fit-content;
}

.s-filter-type{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.s-filter-row{
    display: flex;
    
}

.s-f-card , .s-f-card-h{
    width: 100px;
    height: 150px;

    text-align: center;

    opacity: 0.5;
}
.s-f-card-h{
    opacity: 1;
    font-weight: 700;

    color: white;
    
}
.sel_style_txt{

    color: #14213D;
    font-weight: bold;
    /* background-color: rgb(20, 33, 61); */
}
.not_sel_txt{
    color: #14213D;
}
.s-f-card:hover{
    opacity: 1;
}

.dis_hov:not(.nohover):hover { 
    opacity: 0.3 !important;
}

.s-f-img{
    width: 100%;
    height: 80%;
}

.left-panel{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: white 
    /* rgba(20, 33, 61, 0.1); */
}

.indication{
    width: 30vw;
    position: absolute;
    height: 200px;
    /* left: 30vw; */
    padding: 30px;
    background-color: white;
    z-index: 103;
    border-radius: 10px;
}
.indication button {
    padding: 5px 10px;
}

.category_title{
    position: absolute;
    width: 70%;
    background-color: #14213D;
    border-radius: 20px;
    top: 5px;
    color: white;
    text-align: center;
}

/* /// scrooll of fabrics */

/* .styleSection {
    width: 200px;
    height: 400px;
     background: red;
    overflow: scroll;
 } */
 .styleSection::-webkit-scrollbar, .view_details::-webkit-scrollbar{
     width: 10px;
 }
 .mob-main-2::-webkit-scrollbar {
    width: 20px;
 }
 
 .styleSection::-webkit-scrollbar-track, .mob-main-2::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
     border-radius: 5px;
 }
 .view_details::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
    border-radius: 5px;
}
 
 .styleSection::-webkit-scrollbar-thumb, .view_details::-webkit-scrollbar-thumb, .mob-main-2::-webkit-scrollbar-thumb {
     border-radius: 5px;
     background-color: #14213D;
     background: #14213D;
     /* -webkit-box-shadow: inset 0 0 6px rgba(20, 33, 61);  */
     
 }
 .mob-main-2::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 5px solid transparent;
 }
/*    */

@media (max-width: 480px) {
    
    .s_f_t_c2{
        left: -10px;
    }
    .indication{
        width: 90vw;
    }
    
    .closed {
      transform: translateX(-100%);
      
    }

    .arrow-d{
        
        top: 55% !important;
        opacity: 0.6;
    }
    .v-arr-l, .v-arr-r {
        width: 30px;
        height: 25px;
    }

    .v-arr-r{
        right: 5% !important;
    }
    .v-arr-l{
        left: 5% !important;
    }
  
    .s-nav_list {
      display: none !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 20px;
    }
  
    .s-nav-shade {
      font-weight: bold;
      color: #14213D; /* Adjust text color for active state */
    }
  
    .s-logo {
      display: none; /* Optional: Hide logo on mobile view */
    }

    /* mobile bottom logos nav */

    .mobile_nav_bottom{
        bottom: 15vh;
        width: 100%;
        padding-top: 10px;
        backdrop-filter: blur(8px);
        background: rgb(255, 255, 255, 0.4);
        /* background-image: linear-gradient(0deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255, 0.7) 60%, rgba(255, 255, 255, 0.3) 100%); */
        
    }
    .m-b-logos{
        background: transparent;
        display: flex;
        padding-left: 6%;
        gap: 6%;
        /* user-select: none; */
    }
    .m-b-logo-single label {
        user-select: none;
    }
    .m-b-logo-single{
        width: 27%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .tut_arrow{
        position: absolute;
        top: -105%;
        left: 10%;
        display: flex;
        flex-direction: column;
    }
    .tut_arrow span{
        position: absolute;
        left: -50%;
        top: 10%;
        font-size: 0.7rem;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .tut_arrow img{
        width: 40px;
    }
    .move{
        animation: shake 2s infinite;
    }

    .m-b-logos-line{
        height: 10px;
        width: 100%;
        position: absolute;
        bottom: 0;
        /* background-color: green; */
        background-image: linear-gradient(0deg, rgba(	20, 33, 61, 0.5) 0%, rgba(	20, 33, 61, 0.4) 30%, rgba(	20, 33, 61, 0.1) 100%);
    }

}

@media (max-width: 480px) {
    .sectionSectionFull {
      width: 100%;
      position:fixed;
      z-index: 1000;
    }
    .proceed-mobile-button{
     position: absolute;
    }
    .v-icons{
    display: none;
    }
    .filterButt{
        display: none;
    }
    .f-filterBody{
        display: none;
        
      }
    .s-nav-shade{
        padding: 2px 15px;
        background: rgba(20, 33, 61, 0.8);
        border-radius: 20px;
        color: white;
        
        
        z-index: 101;
        position:sticky;
    }
    .s-nav-shade2{
        padding: 2px 15px;
        background: rgba(20, 33, 61, 0.8);
        border-radius: 20px;
        color: white;
        
        left: 0px;
        z-index: 101;
        position:relative;
    }
    .nav-line{
        width: 40px;
        opacity: 0.8;
        height: 1px;
        background-color: #000000;
        margin-top: -10px;
        margin: 10px;

    }
    .selectFabric, .styleSection{
        width: 0vw;
        height: 100vh;
    
        /* background: rgba(20, 33, 61, 0.1);*/
        background-color: white;
    
        position: relative;
    
        z-index: 1000;
    }
    .mobile-nav-fab{
        display: flex;
        flex-direction: row;
        
    }
    .s-nav_list {
        margin-top: -24vh;
        margin-left: -1vh;
        z-index: 100;
    }
    .image-scroll-sec{
        /* bottom: 20%; */
    }
    .fabric-card{
        height: 100px;
        
        background-size: cover;
        background-position: center;
        color: white;
      
        position: relative;
      }
    
    
    .sel-info{
        width: 40%;
        height: 40%;
        object-fit: contain;
        z-index: 100;
    }
    .mob-main{
        display: flex;
        flex-direction: row;

    }
    .card1{
        height: 100px;
        background-image: url("../assets/selectF.jpg");
        width: auto;
        color: white;
        /* margin-bottom: 2vh; */
        position: relative;
        display: block;
        break-inside: avoid;


    }
    .mob-col{
        display: flex;
        flex-direction: row;
    }
    .card-body1{
        display: flex;
        flex-direction: row;
    }
    .sel-info-div{
        display: flex;
        flex-direction: column;
    }
    .s-nav2{
        padding: 2px 15px;
        border: 1px solid rgb(20, 33, 61);
        border-radius: 20px;
        color: white;
        color: black;
        
        z-index: 101;
        position:sticky;
    }
    .s-nav{
        padding: 2px 15px;
        
        border-radius: 20px;
        color: black;
        
        
        z-index: 101;
        position:sticky;
    }
    .viewFabric{
        width: 100%;
        left: 0vh;
        overflow-y: hidden;
        position: absolute;
        top: 0;
    }
    body {
        /* overflow: hidden; */
        height: 100%;
        margin: 0;
    }
    .viewBody{
        width: 100%;
        height: calc(100svh - 92px) !important;
        top: 0;
        overflow-y: hidden;
        touch-action: none; /* Prevents touch-based scrolling */
    overscroll-behavior: none;
    /* position: fixed !important; */
    }
    .Fabric{
        height: 100svh;
        overflow: hidden;
        position: absolute;
        top: 0;
    }
    .v-display{
        /* position: fixed; */
        width: 100% !important;
        overflow-y: hidden;
    }
    .v-display{
        touch-action: none; /* Prevents touch-based scrolling */
    overscroll-behavior: none;
        top: 0;
        height: calc(100svh - 92px) !important;
      }
    .v-d-body{
        height: 100%;
        overflow-y: hidden;
    }
    .Fabric{
        background-color: #E5E5E5 !important;
    }
    .fabricbody{
        height: 100svh !important;
        width: 100vw;
        overflow: hidden;
        top: 0;
        position: fixed;
    }
    .v-display-img-full {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit:cover;
        overflow-y: hidden;

        /* Maintain aspect ratio */
      }
      .shirt_mob_scale{
        object-fit: contain !important;
        transform: scale(0.9);
        left: 0;
      }
      .kurta_mob_scale{
        object-fit: contain !important;
        transform: scale(1.05);
        left: 0;
      }
      .sadri_mob_scale{
        object-fit: contain !important;
        transform: scale(1.1);
        left: 0;
        /* top: 50px; */
      }
      .pajama_mob_scale{
        object-fit: contain !important;
        transform: scale(1);
        left: 0;
        top: -50px;
      }
    .s-nav_list{
        left: 0 !important;
        width: 100% !important;
        margin-left: 50%;
        position: fixed !important;
        height: 10% !important;
        margin-top: -43vh;
        
    }
    /* Styles for the burger menu */
    .burger-menu {
        position: fixed;
        top: 8vh; /* Adjust the distance from the top */
        left: 5vw; /* Adjust the distance from the left */
        z-index: 110; /* Ensure it is above the sidebar and overlay */
        cursor: pointer; /* Pointer cursor on hover */
        width: 20vw; /* Set width */
        height: 6vh; /* Set height to match width for a circular shape */
         /* Fully circular */
        text-align: justify;
        border-radius: 10%;
        background-color: #D3D3D3; /* Background color */
        color: white; /* Text color */
        display: flex; /* Centering content */
        align-items: center; /* Centering vertically */
        justify-content: center; /* Centering horizontally */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
        transition: background-color 0.3s ease; /* Smooth background color transition */
    }
    .burger-menu2 {
        position: fixed;
        top: 15vh; /* Adjust the distance from the top */
        left: 5vw; /* Adjust the distance from the left */
        z-index: 100; /* Ensure it is above the sidebar and overlay */
        cursor: pointer; /* Pointer cursor on hover */
        width: 20vw; /* Set width */
        height: 6vh; /* Set height */
        background-color: #D3D3D3; /* Background color */
        color: white; /* Text color */
        border: none; /* Remove border */
        border-radius: 10%; /* Keeps bottom right flat */
        display: flex; /* Centering content */
        align-items: center; /* Centering vertically */
        justify-content: center; /* Centering horizontally */
         /* Add shadow for depth */
        overflow: hidden; /* Ensures content doesn't overflow */
        transition: background-color 0.3s ease; /* Smooth background color transition */
    }
    
    .burger-menu-icon {
        font-size: 24px; /* Adjust the size of the burger icon */
        color: #333; /* Adjust color as needed */
    }
    .burger-menu-icon2 {
        font-size: 24px; /* Adjust the size of the burger icon */
        color: #333; /* Adjust color as needed */
    }
    
    /* Sidebar close button styles */
    .close-sidebar_r {
        position: absolute;
        top: -10px;
        right: 16px;
        height: fit-content;
        font-size: 40px;
        cursor: pointer; /* Pointer cursor on hover */
        z-index: 1001; /* Ensure it is above the sidebar content */
    }
    
    .close-sidebar:hover {
        color: #f00; /* Color change on hover, optional */
    }

/* Styles for the sidebar */
.selectFabric2 {
    position: fixed; /* Use absolute positioning */
    width: 50%; /* Adjust width as needed */
    height: 100vh;
    /* background-color: #EAEAEA; */
    /* background: rgb(255, 255, 255, 0.7); */
    backdrop-filter: blur(8px);
    background: rgb(255, 255, 255, 0.4);
    /* background : linear-gradient(0deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255, 0.5) 40% ); */
    box-shadow: 2px 0px 5px rgba(0,0,0,0.3);
    transform: translateX(-100%); /* Hide the sidebar off-screen */
    transition: transform 0.3s ease; /* Smooth transition */
    z-index: 100; /* Ensure it's above other content but below the burger menu */
}
.s-f-card{
    width: 80px;
    opacity: 1 !important;
}
.s-f-card-h{
    width: 80px;
}
.styleSection {
    position: fixed; /* Use absolute positioning */
    left: 0%;
    width: 50%; /* Adjust width as needed */
    height: 100vh;
    background-color: #D3D3D3;
    
    backdrop-filter: blur(8px);
    background: rgb(255, 255, 255, 0.4);
    /* background : linear-gradient(0deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255, 0.7) 40% ); */
    
    /* background: rgb(20, 33, 61, 0.8);
    background : linear-gradient(0deg, rgba(20, 33, 61) 0%, rgba(20, 33, 61, 0.7) 45% ); */
    /* color: white; */
    box-shadow: 2px 0px 5px rgba(0,0,0,0.3);
    transform: translateX(-100%); /* Hide the sidebar off-screen */
    transition: transform 0.3s ease; /* Smooth transition */
    z-index: 1009; /* Ensure it's above other content but below the burger menu */
}

.expand-button {
    position: fixed;
    right: 0vw;
    top: 38vh;
    transform: translateY(-50%);
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    border-radius: 50%; /* Makes the button circular */
    background-color: #D3D3D3; /* Background color */
    color: black; /* Arrow color */
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 50px;
    font-weight: 100; /* Adjust arrow size */
    transition: background-color 0.3s ease, width 0.5s ease; 
    z-index: 1000;
    
}
.expand-button1, .expand-button0{
    position: fixed;
    top: 50vh;   
    left: 50%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(20, 33, 61, 0.7);
    border-left: none;
    padding: 5px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.expand-button0{
    left:90% ;
}
.expand-button2 {
    position:fixed;
    
    right: 0vw;
    top: 50vh;
    transform: translateY(-50%);
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%; /* Makes the button circular */
    background-color: #D3D3D3; /* Background color */
    color: black; /* Arrow color */
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 50px;
    font-weight: 500; /* Adjust arrow size */
    transition: background-color 0.3s ease;
    z-index: 1000;
}


/* Arrow symbol */
.styleSection:not(.expanded) .expand-button2::before {
    transition: background-color 0.3s ease;
    content: '\2192'; /* Right arrow symbol */
}
.selectFabric2:not(.expanded) .expand-button::before {
    transition: 0.3s ease;
    content: '\2192'; /* Right arrow symbol */
}

/* Arrow pointing left when expanded */
.selectFabric2.expanded .expand-button::before {
    content: '\2190'; /* Left arrow symbol */
    font-size: 40px;
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-left: 0vw;
    margin-top: -90%;
    transition: width 0.5s ease;
}
.styleSection.expanded .expand-button2::before {
    content: '\2190'; /* Left arrow symbol */
   
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-left: -2vw;
    transition:  0.3s ease;
}
.selectFabric2.expanded{
    width: 90%;
    transition: 0.3s ease;
}
.styleSection.expanded{
    width: 90%;
    transition:  0.3s ease;
}
.selectFabric2.hidden {
    transform: translateX(-100%);
    transition:  0.3s ease;
}
.styleSection.hidden {
    transform: translateX(-100%);
    transition:  0.3s ease;
}
.selectFabric2.open {
    transition:  0.3s ease;
    transform: translateY(0); /* Bring the sidebar into view */
}
.selectFabric2.show {
    transition:  0.3s ease;
    transform: translateY(0); 
    overflow-y: scroll;/* Bring the sidebar into view */
}
.styleSection.show {
    transform: translateY(0);
    transition:  0.3s ease;
     /* Bring the sidebar into view */
}
.styleSection.open {
    transform: translateY(0);
    transition:  0.3s ease;
     /* Bring the sidebar into view */
}

/* Show the sidebar when menuOpen is true */
.selectFabric2.visible {
    visibility: visible; /* Make it visible */
    opacity: 1; /* Make it opaque */
}
.styleSection.visible {
    visibility: visible; /* Make it visible */
    opacity: 1; /* Make it opaque */
}
.selectFabric2.show {
    left: 0%;
    
    transform: translateX(0);
}
.styleSection.show {
    transform: translateX(0);
    overflow-x: hidden;
}




/* Optional: Styles for the overlay */
.overlay {
    position: fixed; /* Cover the whole viewport */
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    visibility: hidden; /* Initially hidden */
    opacity: 0; /* Initially hidden */
    transition: visibility 0.3s, opacity 0.3s; /* Smooth transition */
    z-index: 998; /* Ensure it's below the sidebar */
}

/* Show the overlay when open */
.overlay.visible {
    visibility: visible; /* Make it visible */
    opacity: 1; /* Make it opaque */
}

/* Main content styles (ensure it does not get pushed) */
.content {
    position: relative; /* Ensure it stays in its position */
    z-index: 100; /* Make sure it's below the sidebar */
}
.shirt-option{
    left: 0px;
}
.s_fabric_type{
    align-items: flex-start;
    width: 50vw;

    
    display: flex;
    /* top: 120%; */
    left: -12%;
    /* padding: 20px 20px; */
    position: relative;
    gap: 3px;
    /* gap: 10px; */
    background-color: transparent;

}
.s-options-fabric{
    display: flex;
    width: fit-content;
    position: relative;
    top: 5vh;
    
    
}
.s-title{
    margin-top: 10%;
}
.s-filter-body{
    position: relative;
    margin-left: 10px;
}
.s_f_t_c{
    text-align: center;
    border-radius: 15px;
    margin: 2px;
    color: white;
    width: 70px;
    height: fit-content;
    position: relative;
    color: black;
    gap: 1px;
    /* background-color: #14213D; */
}
.s-extra{
    height: 100%;
    width: 40px;
    /* top: 70%; */
    background-color: #bdbbbb;
    position: relative;
    right: 12%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

}
.s-extra1{
    height: 100%;
    width: 30px;
    /* top: 70%; */
    left: 5px !important;
    background-color: #bdbbbb;
    position: relative;
    /* left: -12%; */
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;

}
.sidebar-50{
    margin-left: 0vw;
    column-count: 1;
}
.sidebar-100{
    column-count: 2;
    height: 100%;
    /* width: 86vw; */
    
   /* ;margin-left: -5vw; Two columns for 100% sidebar */
  }

.fabricList {
    height: fit-content;
    flex-wrap: wrap;
    
  }

.font-50{
    font-size: 20px;
}

.ext-50{
    right: 1px !important;
    /* display: none; */
    
}
.type-50{
    position: relative;
    left: 0px;
    width: 50px !important;
}
.ext1-50{
    
    left: 0px !important;
    
    /* display: none !important; */
}
.ext1-100{
    left: 0vw;
}
.ext-100{
    left: -1vw;
}
.type-100{
    width: 70vw;
    left: 0vw;
}

.button2-50{
    width:calc(85% - 10vw) ;
    
}
.button2-100{
    width:calc(100% )
}
.button3-50{
    margin-left: 20vw;
}
.button3-100{
    width:calc(100% - 3vw);
    margin-left: 0vw;
}

.view_details{
    width: 90%;
    /* background: transparent; */
    backdrop-filter: blur(12px);
    background: rgb(255, 255, 255, 0.7);
    /* font-weight: bold; */
}
.opac{
    width: 100%;
    left: 0;
    position: fixed;
}
.v-m-d-f-comp{
    /* gap: 20px; */
    font-size: 1.2rem;
}
.eachStyleDes{
    gap: 20px;
}

.f-loading{
    background-color: white;
    /* z-index: 1000; */
    position: fixed;
    height: calc(100svh - 92px);
}

.arrow-d {
    z-index: 100;
}
.selectbody{
    /* margin-top: 13vh; */
}
.same_fabric{
    top: 11vh;
}
.left-panel{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    
    /* filter: blur(30px);
    background-color: white; */
    /* background : linear-gradient(0deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255, 0.7) 40% ); */
}
    .s-modal{
        left: 0;
        width: 100%;
        height: 100svh;
    }

    .first_img_mob{
        height: calc(100% - 50px);
        object-fit: contain !important;
    }

    .s-h-icons{
        font-size: 0.8rem;
        
    }
    .v-checkout{
        left: 0;
    }

    .mob_sel_card{
        font-weight: bold;
        backdrop-filter: blur(10px);
        background: rgb(255, 255, 255, 0.5);
        color: black;
    }
    .Dress-up-mobile{
        color: white;
    }

    .options_select{
        width: 90%;
        background-color: #EAEAEA;
        height: 30px;
        position: relative;
        top: 35px;
        font-size: 0.8rem;
        display: flex;
    }
    .main_options_mob{
        display: flex;
        font-size: 0.8rem;
        background-color: #EAEAEA;
    }
    .main_options_mob label{
        margin: 0;
        height: fit-content;
        text-align: center;
    }
    .sel_edit_icon{
        width: 25px;
        object-fit: contain;
        position: absolute;
        right: 0;
    }
    .sel_edit_icon img{
        width: 100%;
        height: 100%;
    }
    .mob_options_sel{
        background-color: #14213D;
        color: white;
    }

    .cart-v-display-img-shirt{
        transform: scale(1.4);
        top: 20%;
        left: 0;
    }
    .cart-v-display-img-pant{
        transform: scale(1.4);
        top: -20%;
        left: 0%;
    }
    .img-left{
        left: 0;
    }
}

@keyframes shake {
    0%, 100%, 20%, 80% {
        transform: translate3d(0, 0, 0);
    }
    30%, 70%{
        transform: translate3d(0, 1px, 0);
    }
    40%, 60%{
        transform: translate3d(0, 4px, 0);
    }
    50%{
        transform: translate3d(0, -7px, 0);
    }

}
  
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    .sel-ins-des{
        font-size: 0.6rem;
    }
    .viewOpac button{
        left : calc(80px + 10vw)
    }
    .s-options-fabric{
        height: 35px;
    }
    .s-extra, .s-extra1, .s-extra2{
        width: 35px;
    }
    .edit_type{
        height: 35px !important;
    }
    .s-f-foot{
        font-size: 0.5rem;
    }
    .card1{
        height: 80px;
    }
    .s-f-card, .s-f-card-h{
        width: 70px;
        height: 105px;
    }
    .view_details{
        width: 85%;
    }

    .filterButt{
        font-size: 0.8rem;
    }
    .s-title img{
        width: 17px !important;
    }

    .styleSection::-webkit-scrollbar {
        width: 7px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .sel-ins-des{
        font-size: 0.6rem;
    }
    .viewOpac button{
        left : calc(100px + 10vw)
    }
    .s-options-fabric{
        height: 35px;
    }
    .s-extra, .s-extra1, .s-extra2{
        width: 35px;
    }
    .edit_type{
        height: 35px !important;
    }
    .s-f-foot{
        font-size: 0.6rem;
    }
    .card1{
        height: 85px;
    }
    .s-f-card, .s-f-card-h{
        width: 75px;
        height: 110px;
    }
    .view_details{
        width: 85%;
    }
    .v-checkout{
        left: 50%;
    }

    .filterButt{
        font-size: 1rem;
    }
    .s-title img{
        width: 20px !important;
    }

    .styleSection::-webkit-scrollbar, .view_details::-webkit-scrollbar {
        width: 8px;
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px){
    .sel-ins-des{
        font-size: 0.7rem;
    }
    .viewOpac button{
        left : calc(100px + 10vw)
    }
    .s-f-card, .s-f-card-h{
        width: 90px;
        height: 130px;
    }

    .view_details{
        width: 85%;
    }

    .v-checkout{
        left: 20%;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {

} */

    
    
  
/*    */