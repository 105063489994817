.pay_p{
    width: 100%;
    /* display: flex; */
    gap: 20px;
    min-height: 650px;
    height: fit-content;
    padding-top: 120px;
    margin-bottom: 50px;
}

.pay_p .cost{
    position: absolute;
}
.pay_p_left{
    width: 50vw;    
    margin-left: 10vw;
    height: fit-content;
}

.pay_options .accordion-item{
    width: 100% !important;
}
.pay_options .accordion-header::after{
    pointer-events: none;
}

.pay_options .accordion-button::after{
    width: 20px;
    height: 20px;
    background-image: none;
    background-color: white;
    border: 7px solid #14213D;
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
}

.pay_options .accordion-button.collapsed::after{
    background-color: white;
    border: 1px solid #14213D;
}

.pay_options .accordion-button #text{
    position: relative;
    left: 30px;
}
.pay_options .accordion-button:not(.collapsed){
    pointer-events: none;
    background-color: #eaeaea;
}
.pay_options .accordion-body{
    background-color: #eaeaea;
}

.pay_save{
    position: absolute;
    right: 20px;
    background-color: #00a31b;
    color: white;
    padding: 2px 8px;
    border-radius: 5px;
}
.pay_logos{
    display: flex;
    gap: 10px;
    width: fit-content;
    margin-bottom: 20px;
}
.pay_logos img{
    width: 30px;
    height: 30px;
    object-fit: contain;
}
@media screen and (max-width: 480px) {
    

    .pay_p{
        flex-direction: column;
        overflow: unset;
    }

    .pay_p .cost{
        position: static;
    }

    .pay_p_left{
        width: 90vw;
        position: relative;
        left: 50%;
        margin-left: 0;
        transform: translateX(-50%);
    }

    .pay_options .accordion{ width: 100%; }
    
    .pay_options .accordion-item { width: 100%; }
    .pay_options .accordion-header { width: 100%; }
    .pay_options .accordion-button{ width: 100%; }
    .pay_options .accordion-body { width: 100%; }

}