body, html{
  margin: 0;
  font-family: "TwentiethCentury" !important; 
  /* // "Akshar", sans-serif !important; */
  /* overflow-x: hidden; */
}

@font-face {
  font-family: "TwentiethCentury";
  src: local("TwentiethCentury"),
    url("./assets/TwentiethCenturyforKenmoreLight.ttf") format("truetype");
  font-weight: normal;
}

.App {
  width: 100%;
  /* height: 100vh; */
}

.logo_main{
  width: 150px;
}
.title-font{
  font-size: 3.5rem;
}

.title-font1{
  font-size: 3rem;
}

.txt-font{
  font-size: 1.75rem;
}
.txt-font2{
  font-size: 1.75rem;

}
.txt-font1{
  font-size: 1.25rem !important;
}
.txt-small{
  font-size: 0.7rem;
}

.txt-small1{
  font-size: 0.7rem;
}

.a_tag{
  color:#0000EE !important;

}
/* All buttons */

.Butt1, .Butt2, .Butt1last{
  /* background: transparent; */
  /* background: url('./assets/Butt1.svg') no; */

  background-size: contain;

  padding: 12px 25px;
  color: white;
  border: 2px solid #FFF;
  box-shadow: 4px 4px 0px 0px rgba(255, 255, 255, 0.64);
}
.Butt1, .Butt2, .Butt1last{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #14213D;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  border: none;
}
.Butt2{
  background: #FCA311 !important;
}
.HomePage{
  overflow-y: auto;
  overflow-x: hidden;
}

.Butt1:hover::after , .Butt2:hover::after{
  right: auto;
  left: 0;
  width: 100%;
}

.Butt1 span, .Butt2 span{
  text-align: center;
  text-decoration: none;
  width: 100%;
  color: #fff;
  z-index: 20;
  transition: all 0.3s ease-in-out;
}

.Butt1:hover span {
  color: #183153 !important;
  animation: scaleUp 0.3s ease-in-out;
  color: white;
}
.Butt2:hover span {
  color: #FCA311 !important;
  animation: scaleUp 0.3s ease-in-out;
  color: white;
}

.Terms_main{
  width: 90%;
  margin-top: 60px;
}

.display_none{
  display: none !important;
}
.opacity_03{
  opacity: 0.3 !important;
}
@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.Butt1:after , .Butt2:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #FCA311;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  color: white;
}
.Butt2:after {
  background: #183153;
}


.filterButt{
  background: transparent ;
  box-shadow: none;
  
  padding: 1px 15px;
  border: 1px solid #14213D;
}

.hori_center{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.verti_center{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.both_center{
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/*     */

/* Footer */

.foot1{
  background: #14213D;
  color: white;
  position: relative;
  padding: 2%;
  padding-top: 20%;
  /* margin-top: 50px; */
}

.foot_containter{
  width: 100%;
  display: flex;
  gap: 5px;
}


.foot_first{
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.foot_second{
  width: 20%;
}
.foot_third{
  width: 20%;
}
.foot_forth{
  width: 20%;
}
.foot_first img{
  width: 250px;
}

.foot_containter ul, li{
  text-decoration: none;
  padding: 0;
}
.foot_des{
  width: 80%;
  text-align: justify;
}

.foot_tag{
  color: #FCA311;
  text-align: center;
}

.foot_end_wrap{
  position: relative;
  height: 80px;
}
.foot_end{
  position: absolute;
  right: 0;
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.foot_end label{
  margin: 0;
}


.foot_razor{
  width: 150px;
}

.mobile_links{
  display: none;
}
.social{
  
  position: relative;
  top: 20px;
  display: flex;
  gap: 15px;
  /* margin-left: 32px; */
  width: fit-content;
  height: fit-content;
}

.footer_label{
  width: 100%;
  padding: 3% 5%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.f_l_ele_card{
    width: 20% ;
    background-color: #14213D;
    height: 120px;
    padding: 1%;
    border-radius: 8px;
    text-align: center;
    display: flex;
    gap: 5px;
    flex-direction: column;

}
.f_l_ele_card label{
  margin: 0;
  
}
.foot_containter li{
  width: fit-content;
  color:white !important  
}
.foot_containter a{
  color: white;
}
.foot_containter a:hover , .foot_containter li:hover{
  color: #FCA311;  
}

.f_l_ele_card:hover{
  background-color: #FCA311;
  color: black;
  transform: scale(1.15);
}
.f_l_ele_img{
  height: 80%;
}

.f_l_ele_img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


/* ///////////// */

.FIT_W{
  width: fit-content;
}
.FIT_H{
  height: fit-content;
}

.social_l{
  width: 30px;
}


li{
  text-decoration: none;
  list-style: none;

}

.foot_list li:hover{
  color: #FCA311;
}

/* Navbar */

.NavBar{

  z-index: 100;
  position: absolute;
  width: 100%;
  background-color: #14213D;
  top: 0;
  padding-top: 1%;

  padding-bottom: 1%;
}
.patti_nav{
  padding-top: 45px !important;
}

.Navbody{
  height: fit-content;
  /* padding: 15px; */
  color: white;
  text-align: center;
  position: relative; 

  /* display: flex; */
}
.nav-left{
  gap: 3vw !important;
}
.nav-left div:hover{
  color: #FCA311;
}
.nav-left, .nav-right{
  display: flex;
  gap: 1.5vw;

  height: 100%;
  top: 0;

  position: absolute;

  align-items: center;
}

.nav-left{
  left:3vw;
}

.nav-right{
  right:3vw
}

.nav-logos{
  transform: scale(80%);
  /* width: 50px;
  height: 50px;
  z-index: 102; */
}
/*    */

/* Login page  */

.auth{
  /* top: 20vh; */
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: #EAEAEA;
}
.login{
  position: absolute;
  /* right: 10vw; */
  width: 30vw ;
  left: 65vw;
  padding: 2%;
  height: fit-content;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 80px;

  /* z-index: 1000; */
}

.login_type{
  width: 90%;
  display: flex;
  z-index: 1000;
}
.login_type label{
  margin: 0;
  width: 50%;
  border-radius: 20px;
}
.login_t_left{
  border-radius: 20px;
}

.L-email{
  min-width: 200px;
  /* width: 90%; */
  padding: 15px;
  margin-top: -10px;
  width: 100%;
  background: transparent;
  border: rgb(141, 141, 141) solid 1px;
}
.login .Butt1{
  padding-top: 10px;
  padding-bottom: 10px;
}
.otp_inp{
  position: absolute;
  /* z-index: -1; */
  /* top: -100%; */
  bottom: 0;
  /* transform: translateY(-100%); */
  transition-duration: 0.5s;
}
.otp_inp .css-tccdxk {
  gap: 10px;
  width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.otp_inp .css-tccdxk input{ 
  /* height: 50px; */
}

.otp_cover{
  width:100%;
  height:100%;
  position:fixed;
  background-color:#EAEAEA;
  z-index: 1;
}

.google_logo{
  width: 150px;
}

.terms_include button{
  text-decoration: none;
  border: none;
  background: transparent;
  margin: 0;
  color: #0000EE;
}

.terms_modal{
  height: 80vh;
  position: fixed;
  background-color:white;
  padding: 20px;
  width: 40vw;
}

.Terms_Page li{
  list-style-type: square;
}
@media (max-width: 480px) {
  .mix-match-mobile {
    display: none;
  }
  .address_f{
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0.9rem !important;
  }
  .terms_modal{
    width: 90vw;
  }

  .txt-font1{
    font-size: 0.8rem !important;
  }
  .foot_razor{
    width: 100px;
  }

}


@media (max-width: 480px) {
  .viewOpac {
    display: none;
  }
  @media (max-width: 480px) {
    
  
    .Dress-up-mobile {
      text-align:center;
      margin-top: -25%; /* Adjust the top margin as needed */
    }
    
    .foot_list{
      font-size: 0.7rem;
    }
    
  
    .txt-font3 {
      font-size: 18px; /* Adjust font size as needed */
      
      text-align: left;
      margin-left: 40px;
      
    }
  }
  

  

  
  .selectFabric .sel-card .sel-card-img-container {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .selectFabric .sel-card , .clone_m_right_row .sel-card   {
    width: calc(40vw - 20px); /* Adjust the calculation as per your design */
    height: calc(40vw - 20px);
    object-fit: contain !important; /* or 'cover', 'fill', 'scale-down', etc. based on your needs */
    margin-top: 20px;
    margin-left: 30px;
  }
  .selectFabric .sel-card-img, .clone_m_right_row .sel-card-img {
    
    height: 320%;
    object-fit:contain;
  }
  
  .proceed-button-mobile {
    position: relative;
    bottom: 0px; /* Adjust bottom margin as needed */
    margin-top: 60px;
    /* transform: translateX(-35%); */
    z-index: 10; /* Ensure button appears above other content */
  }
  .proceed-button-mobile button{
    padding: 10px 17px;
  }
  .selectFabric {
    height: 100%; /* or auto depending on your layout needs */
    overflow-y: auto; /* Enable vertical scrolling when content exceeds container height */
  }
  .mobile-button{
    
    
    z-index: 10;
  }
  .proceed-button-mobile{
    position: relative;
    margin-bottom: -80px;
    margin-left: 250px;
   }
   .Butt2 {
    color: black;
    /* font-weight: bold; */
   }
   .v-icons{
    display: none;
   }
   
   



.mobile-bottom-container {
    display: none; /* Initially hidden, only shown on mobile */
    position: relative;
    
    width: 100%;
    height: fit-content; /* Make the container occupy the full screen height */
    /* min-height: 110px; */
    background: transparent;
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 100;
}

.mobile-bottom-item {
    margin: 5px 0;
    width: 50%;
}

.v-des_section {
  display: none; /* Hide the details section on mobile */
}

.mobile-container{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background: transparent;
}
.money-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 25px;
  background-color: rgba(20, 33, 61, 0.1);
}

.custom-set-1 {
  text-align: left;
  /* background-color: rgba(20, 33, 61, 0.1);; */
}

.title-font1 {
  font-size: 18px; /* Adjust font size as needed */
  font-weight: bold; /* Example font weight */
}

.txt-font-currency {
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold; /* Example font weight */
}
.mobile-bottom-item{
  display: flex;
  flex-direction: row;
  text-align: left;
  /* margin-top: -13px; */
  
}
.Butt1last{
  width: 35%;
  height: 50px;
  background-color:#FCA311;
  border: none !important;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.bottom-last{
  display: flex;
  position: relative;
  text-align: left;
  color: black;
  opacity: 1;
}

@media (max-width: 480px) {
  .mobile-image {
    width: 100%; /* or any other dimension you need */
    height: auto;
     /* maintain aspect ratio */
  }
  .Butt3{
    /* background: transparent; */
    /* background: url('./assets/Butt1.svg') no; */
  
    background-size: contain;
  
    padding: 12px 25px;
    color: black;
    border: 2px solid black;
    box-shadow: 4px 4px 0px 0px rgba(255, 255, 255, 0.64);
  }
  .Butt2, .Butt1last{
    /* background: transparent; */
    /* background: url('./assets/Butt1.svg') no; */
  
    background-size: contain;
  
    padding: 8px 10px;

    box-shadow: 4px 4px 0px 0px rgba(0,0, 255, 255, 0.64);
  }
}
@media (max-width: 480px) {
  
  .Head{
    position:fixed;
    /* top: 0vh; */
    top: 32px;
    right: -34px;
    
    z-index: 100;
    
    
    transform: rotate(-90deg);
  }
  .logo_name{
    background-color: #14213D;
    padding: 5px;
    width: 100px;
    z-index: 10000;

  }
  .main-mob{
    position: relative;
  }

  /* Footer */
  .foot_containter{
    flex-direction: column;
  }
  .foot_first{
    width: 100%;
  }
  .foot_first img{
    width: 150px;
  }
  .foot_des{
    font-size: 0.6rem;
    width: 90%;
  }
  .foot_second{
    display: none;
  }
  .foot_third{
    display: none;
  }
  .foot_forth{
    display: none;
  }

  .mobile_links{
    width: 90%;
    display: block;
    margin-top: 20px;
  }

  .mobile_links .accordion{
    width: 100%;
    color: white;
  }
  .mobile_links .accordion-item{
    width: 100%;
    background-color: transparent;
    color: white;
    border: none;
    /* border-left: none; */

  }
  .mobile_links button{
    background-color: transparent !important;
    color: white !important;
    box-shadow: none !important;
    border-top: 1px solid white;
    padding-left: 0;
    /* border-bottom:1px solid white; */
  }
  .mobile_links .accordion-body{
   padding-top: 0; 
  }
  .foot_end_wrap{
    height: fit-content;
  }
  .foot_end{
    position: relative;
    flex-direction: column;
  }
  .footer_label{
    padding: 3% 2%;
  }
  .foot1{
    padding-top: 35%;
  }
  .f_l_ele_card{
    height: 95px;
    font-size: 0.7rem;
    padding-top: 3%;
  }

  .f_l_ele_img{
    height: 60%;
  }


  .social{
    top: 0;
    margin-bottom: 10px;
  }


  /* .mobile_links .accordion-button{
    border: none !important;
  } */


}
@media (max-width: 480px) {
  
  
  
  
  
  

.mobile-top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 16.5%; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 8%; /* Adjust height as needed */
    background-color: #efefef; /* Example background color */
    color:  black;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}










.select-1-fab {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.select-1-fab:hover {
  background-color: #f0f0f0;
}

.dropdown-content {
  position: absolute;
  top: -120%; /* Adjust to position correctly relative to container */
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  
  z-index: 2; /* Ensure this has a higher z-index than the container to appear above it */
}

.select-1-fab {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.select-1-fab:hover {
  background-color: #e0e0e0;
}

.select-1-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.select-1-txt {
  flex-grow: 1;
}

.select-1-price {
  font-weight: bold;
}

.card-right {
  display: flex;
  width: 20vh;
  height: 6vh;
  padding: 2px;
  margin-bottom: 10px;
  margin-top: 10px;
   /* Optional border style */
  border-radius: 4px; /* Optional border radius */
  background-color: white; /* Optional background color */
}
.card-image{
  width: 20vh;
  height: 5.5vh;
  
  
}

@media screen and (max-width: 480px)  {
  .txt-font2{
      font-size: 24px; 
      font-weight: bold;
      text-align: left;
      margin-left: 10%;
      
      

  }
  
  .mobile-only-text{
    font-size: 10px;
    margin: 15px;
    text-align: center;
  }
  .v-arr-r, .v-arr-l{
    margin-top: -6vh;
    position: absolute;
    width: 40px;
    height: 40px;
  }
  .v-arr-l{
    left: 3% !important;
  }
  .v-arr-r{
    right: 3% !important;
  }
  
  .s-navigation{
    height: 6vh !important;
    margin-top: 45%;
    
    
    left: 0 !important;
    width: 100% !important;
  }
  .opt-50{
    width:38vw!important;
    margin-left: -2vw;
  }
  .opt-100{
    /* width: fit-content; */
  }
  
.foot_right{
  margin-left: -10vw;
}

.login .Butt1{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}


}

















}
.burger-menu {
  position: relative;
  top: 30px; /* Adjust the distance from the top */
  left: 20px; /* Adjust the distance from the left */
  z-index: 100; /* Ensure it is above the sidebar and overlay */
  cursor: pointer; /* Pointer cursor on hover */
}

.burger-menu-icon {
  font-size: 24px; /* Adjust the size of the burger icon */
  color: #333; /* Adjust color as needed */
}
.proceed-btn{
  height: 20px;
  position: absolute;
}
@media (max-width: 480px) {
  .f-loading {
      /* Specific styles for mobile */
      width: 50px;
      height: 50px;
      z-index: 60 !important;
  }

  .nav-mobile-logo{
    width: 150px;
  }

  .title-font{
    font-size: 3rem;
  }
  
  .title-font1{
    font-size: 2rem;
  }
  
  .txt-font{
    font-size: 1.25rem;
  }
  .txt-font2{
    font-size: 0.8rem;
  
  }

  
}

.mobile_vid_login{
  width: 100vw !important;

}
.login{
  width: 90vw;
  left: 50% !important;
}

.foot_logo img {
  width: 150px;
}

.tag_f{
  font-size: 0.8rem;
}
   
}

/* //////////////////// */


/* Nav sidebar  */
.nav_sidebar{
  position: fixed;
  background-color: white;
  height: 100vh;
  width: 350px;
  top: 0;
  left: -350px;
  z-index: 1000;
  transition-duration:0.5s;
  display: flex;
  flex-direction: column;  
}

.nav_sidebar_open{
  transform: translateX(100%);
}
.nav_sidebar_close{
  transform: translateX(-100%);
}
.nav_wrapper{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: none;
  opacity: 0;
  z-index: 999;
  transition: opacity 1s ease-in-out ;
  /* background: transparent; */
  background: rgba(255, 255, 255, 0.515);
}

.sidebar_acc{
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
}
.sidebar_header{
  width: 100%;
  height: fit-content;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 1%;
}

.sidebar_header img{
  width: fit-content;
  height: 100%;
  object-fit: contain;  
}

.logo_wrap{
  /* width: ; */
}
.sidebar_body{
  height: 100%;
  width: 100%;
  margin-top: 20px;
  /* overflow-y: scroll; */
  /* background-color: #0000EE; */
}

.side_bar_nav_body{
  width: 90%;
  padding: 2% 2%;
  border-top: 1px solid #EAEAEA;
  position: relative;
}
.side_bar_nav_body img{
  width: 20px;
  position: absolute;
  right: 0;
  opacity: 0;
  transform: translateY(-50%) rotate(45deg) ;
  transition: transform .2s ease-in-out ;
  /* transition: opacity .5s ease-in-out; */
}

.close_sidebar:hover{
  transform: rotate(90deg);
  transition: transform .2s ease-in-out ;
}

.icon_hero_smg, .icon_hero_smg1{
  position: absolute;
  width: 30px;
  opacity: 0.5;
  left: 5px;
  margin-top: 5px;
}
.icon_hero_smg1{
  top: 90px;
  opacity: 0.2;
}

@media (max-width: 480px) {
  .nav_sidebar{
    height: 100svh;
    width: 90vw;
    left: -90vw;
  }
  .nav_sidebar_open{
    transform: translateX(100%);
  }
  .nav_sidebar_close{
    transform: translateX(-100%);
  }

  .icon_hero_smg, .icon_hero_smg1{
    right: 5px !important;
    left: unset;
    width: 20px;
    margin-top: 10px;
  }
  .icon_hero_smg1{
    margin-top: 0px;
    opacity: 0.2;
  }

  .otp_inp{
    width: 90vw;
    left: -2%;
    padding-bottom: 2%;
  }
  .otp_cover{
    /* background-color: white; */
  }

  .title-font1{
    font-size: 1.5rem;
  }  
  /* .txt-font{
    font-size: 0.7rem;
  } */
  .txt-small1{
    font-size: 0.3rem;
  }
  .title-font{
    font-size: 2rem; 
  }

  .otp_inp{
    position: relative;
  }
  .otp_cover{
    display: none;
  }
}


@media only screen and (min-width: 600px) and (max-width: 767px) {
  .title-font1{
    font-size: 1.7rem;
  }  
  .txt-font{
    font-size: 0.8rem;
  }
  .txt-small1{
    font-size: 0.4rem;
  }
  .Butt1, .Butt2, .Butt1last{
    padding: 7px 10px; 
    font-size: 1rem;
  }
  .title-font{
    font-size: 2.5rem; 
  }

  .foot_first img{
    width: 180px;
  }
  .foot_des{
    font-size: 0.6rem;
  }

  .f_l_ele_card {
    height: 80px;
  }
  .f_l_ele_img{
    height: 65%;
  }
  .foot_first img{
    width: 170px;
  }
  .foot_des{
    font-size: 0.6rem ;
  }

  .f_l_ele_card {
    height: 80px;
  }

  .foot_third div, .foot_second div, .foot_forth div{
    font-size: 0.9rem !important;
  }
  .foot_third li, .foot_second li, .foot_forth li{
    font-size: 0.7rem !important;
  }

  .foot_third, .foot_second{
    width: 15%;
  }
  .foot_forth{
    width: 30%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title-font1{
    font-size: 2rem;
  }   
  .txt-font{
    font-size: 1rem;
  }
  .txt-small1{
    font-size: 0.5rem;
  }

  .Butt1, .Butt2, .Butt1last{
    padding: 10px 15px; 
    font-size: 1rem;
  }
  .title-font{
    font-size: 3rem; 
  }

  .foot_first img{
    width: 200px;
  }
  .foot_des{
    font-size: 0.7rem ;
  }

  .f_l_ele_card {
    height: 100px;
  }

  .foot_third div, .foot_second div, .foot_forth div{
    font-size: 1rem !important;
  }
  .foot_third li, .foot_second li, .foot_forth li{
    font-size: 0.9rem !important;
  }

  .foot_third, .foot_second{
    width: 15%;
  }
  .foot_forth{
    width: 30%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px){
  .title-font1{
    font-size: 2.5rem;
  }

  .txt-font{
    font-size: 1.5rem;
  }
  .title-font{
    font-size: 3.2rem; 
  }

  .foot_first img{
    width: 230px;
  }
  .foot_des{
    font-size: 0.8rem;
  }
}
